/**
 *
 * Input
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

import classNames from 'classnames';

export function Input(props) {
  const {
    label,
    help,
    error,
    hiddenLabel,
    name,
    type,
    defaultValue,
    placeholder,
    LeadingIcon,
    TrailingIcon,
    trailingFaIcon,
    leadingAddon,
    trailingAddon,
    inlineAddon,
    hint,
    onChange,
    trailingIconOnClick,
    leadingIconOnClick,
    disabled,
    ...rest
  } = props;

  return (
    <>
      <div className="flex justify-between">
        {label && (
          <label htmlFor={name} className={`block text-sm font-medium text-gray-700 ${hiddenLabel ? 'sr-only' : ''}`}>
            {label}
          </label>
        )}
        {hint && (
          <span className="text-sm text-gray-500" id={`${name}-hint`}>
            {hint}
          </span>
        )}
      </div>

      <div className="mt-1 flex relative rounded-md shadow-sm">
        {LeadingIcon && (
          <div
            className={classNames(
              'absolute inset-y-0 left-0 pl-3 flex items-center',
              leadingIconOnClick && !disabled ? 'cursor-pointer' : '',
              disabled ? 'cursor-not-allowed' : '',
            )}
            aria-hidden="true"
            onClick={leadingIconOnClick}
          >
            <LeadingIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}

        {inlineAddon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{inlineAddon}</span>
          </div>
        )}

        {leadingAddon && (
          <span
            className="inline-flex items-center px-3 rounded-l-md border border-r-0
            border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
          >
            {leadingAddon}
          </span>
        )}
        <input
          {...rest}
          type={type}
          name={name}
          onChange={(e) => onChange(e.target.value)}
          id={name}
          disabled={disabled}
          className={classNames(
            'flex-1 min-w-0 block w-full pr-10 sm:text-sm focus:outline-none ',
            LeadingIcon ? 'pl-10' : '',
            TrailingIcon ? 'pr-10' : '',
            inlineAddon && (inlineAddon.length > 2 ? 'pl-16' : 'pl-8'),
            error
              ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
              : 'focus:ring-gray-500 focus:border-gray-500 border-gray-300 text-gray-900 placeholder-gray-300',
            disabled ? 'text-gray-400' : '',
            leadingAddon ? 'rounded-r-md rounded-none' : 'rounded-md',
          )}
          placeholder={placeholder}
          defaultValue={defaultValue}
          aria-invalid="true"
          aria-describedby={`${name}-error`}
        />
        {trailingAddon && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{trailingAddon}</span>
          </div>
        )}
        {TrailingIcon && !error && (
          <div
            data-testid={`${name}-clear-button`}
            className={classNames(
              'absolute inset-y-0 right-0 pr-3 flex items-center',
              !trailingIconOnClick ? 'pointer-events-none' : 'z-10 cursor-pointer',
            )}
            aria-hidden="true"
            onClick={trailingIconOnClick}
          >
            <TrailingIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        {trailingFaIcon && !error && (
          <div
            data-testid={`${name}-clear-button`}
            className={classNames(
              'absolute inset-y-0 right-0 pr-3 flex items-center',
              !trailingIconOnClick ? 'pointer-events-none' : 'z-10 cursor-pointer',
            )}
            aria-hidden="true"
            onClick={trailingIconOnClick}
          >
            {trailingFaIcon}
          </div>
        )}
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {help && (
        <p className="mt-2 text-sm text-gray-500" id={`${name}-description`}>
          {help}
        </p>
      )}
      {error && (
        <p className="mt-2 ml-1 text-sm text-red-600" id={`${name}-description`}>
          {error}
        </p>
      )}
    </>
  );
}

Input.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hiddenLabel: PropTypes.bool,
  LeadingIcon: PropTypes.object,
  TrailingIcon: PropTypes.object,
  help: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  inlineAddon: PropTypes.bool,
  trailingAddon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  leadingAddon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  hint: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  trailingIconOnClick: PropTypes.func,
  leadingIconOnClick: PropTypes.func,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['email', 'color', 'number', 'password', 'url', 'color', 'text']),
};

Input.defaultProps = {
  hiddenLabel: false,
  inlineAddon: false,
  type: 'text',
};
export default memo(Input);

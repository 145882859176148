import { find, lowerCase } from 'lodash';

export const ROLE_ADMIN = 'ADMIN';
export const ROLE_RECRUITER = 'RECRUITER';
export const ROLE_MASTER_RECRUITER = 'MASTER_RECRUITER';
export const ROLE_CONTENT_MANAGER = 'CONTENT_MANAGER';
export const ROLE_EVALUATOR = 'EVALUATOR';
export const ROLE_COLLABORATOR = 'COLLABORATOR';
export const ROLE_HIRING_MANAGER = 'HIRING_MANAGER';
export const ROLE_MASTER_CONTENT_MANAGER = 'MASTER_CONTENT_MANAGER';
export const ROLE_TENANT_ADMIN = 'TENANT_ADMIN';

export const meetingParticipantRole = {
  PARTICIPANT: 'PARTICIPANT',
  GUEST: 'GUEST',
  ORGANIZER: 'ORGANIZER',
};

export const getCurrentRole = (roles) => {
  if (find(roles, (role) => lowerCase(role) === lowerCase(ROLE_MASTER_RECRUITER))) {
    return ROLE_MASTER_RECRUITER;
  }
  if (find(roles, (role) => role === ROLE_RECRUITER)) {
    return ROLE_RECRUITER;
  }
  return getDisplayRole(roles);
};

// Role hierarchy ticket: https://github.com/talview/workflow-tools-client/issues/1325

export const getDisplayRole = (roles = []) => {
  if (roles && Array.isArray(roles) && roles.length) {
    if (roles.includes(ROLE_ADMIN)) {
      return ROLE_ADMIN;
    }
    if (roles.includes(ROLE_TENANT_ADMIN)) {
      return ROLE_TENANT_ADMIN;
    }
    if (roles.includes(ROLE_MASTER_RECRUITER)) {
      return ROLE_MASTER_RECRUITER;
    }
    if (roles.includes(ROLE_RECRUITER)) {
      return ROLE_RECRUITER;
    }
    if (roles.includes(ROLE_HIRING_MANAGER)) {
      return ROLE_HIRING_MANAGER;
    }
    if (roles.includes(ROLE_EVALUATOR)) {
      return ROLE_EVALUATOR;
    }
    if (roles.includes(ROLE_COLLABORATOR)) {
      return ROLE_COLLABORATOR;
    }
    if (roles.includes(ROLE_CONTENT_MANAGER)) {
      return ROLE_CONTENT_MANAGER;
    }
    if (roles.includes(ROLE_MASTER_CONTENT_MANAGER)) {
      return ROLE_MASTER_CONTENT_MANAGER;
    }
    return roles[0];
  }
  return '';
};

/* eslint-disable no-param-reassign */
import { memoize, map, filter, isArray, isNull, reject, get } from 'lodash';
import {
  ROLE_EVALUATOR,
  ROLE_HIRING_MANAGER,
  ROLE_MASTER_RECRUITER,
  ROLE_RECRUITER,
  ROLE_TENANT_ADMIN,
} from 'utils/data/roles';
import Auth from 'utils/auth';
import {
  faNetworkWired,
  faFilePen,
  faCalendarCircleUser,
  faCalendarDays,
  faInbox,
} from '@fortawesome/pro-light-svg-icons';
import message from './messages';

export const filterItems = ({ items, roles }) =>
  reject(
    map(items, (item) => {
      const hasItem =
        get(item, 'roles', []).filter((e) => roles && roles.includes(e)).length > 0 &&
        !(get(item, 'excludes', []).filter((e) => roles && roles.includes(e)).length > 0);
      if (isArray(item.subItems)) {
        item.subItems = filter(
          item.subItems,
          (subItem) => subItem.roles.filter((e) => roles && roles.includes(e)).length > 0,
        );
      }
      return hasItem ? item : null;
    }),
    isNull,
  );

export const getItems = () => {
  const roles = Auth.roles || [];
  const items = [
    {
      path: '/workflow',
      label: message.workflows,
      name: 'Workflows',
      icon: faNetworkWired,
      roles: [ROLE_TENANT_ADMIN, ROLE_RECRUITER, ROLE_MASTER_RECRUITER],
    },
    {
      path: '/evaluations',
      label: message.evaluations,
      name: 'Evaluations',
      icon: faFilePen,
      roles: [ROLE_TENANT_ADMIN, ROLE_RECRUITER, ROLE_MASTER_RECRUITER, ROLE_EVALUATOR],
    },
    {
      path: '/panel/interviewer-dashboard',
      label: message.interviews,
      name: 'Interviews',
      icon: faCalendarDays,
      roles: [ROLE_TENANT_ADMIN, ROLE_MASTER_RECRUITER, ROLE_RECRUITER, ROLE_EVALUATOR, ROLE_HIRING_MANAGER],
    },
    {
      path: '/panel/calendar',
      label: message.availability,
      name: 'Availability',
      icon: faCalendarCircleUser,
      roles: [ROLE_TENANT_ADMIN, ROLE_MASTER_RECRUITER, ROLE_RECRUITER, ROLE_EVALUATOR, ROLE_HIRING_MANAGER],
    },
    {
      path: '/notification-inbox',
      label: message.inbox,
      name: 'Inbox',
      icon: faInbox,
      roles: [ROLE_TENANT_ADMIN, ROLE_MASTER_RECRUITER, ROLE_RECRUITER, ROLE_EVALUATOR, ROLE_HIRING_MANAGER],
    },
  ];
  return filterItems({ items, roles });
};

export default memoize(getItems);

import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { handleEnterKeyPress } from 'utils/helpers';

export function PageCard(props) {
  const { icon, name, summary, onClick, route } = props;

  const handleClick = () => {
    onClick(route);
  };

  return (
    <div
      className="flex p-2 hover:bg-gray-100 rounded cursor-pointer"
      onClick={handleClick}
      tabIndex={0}
      onKeyDown={(e) => handleEnterKeyPress(e, handleClick)}
      role="button"
      data-testid={`pageCard-${name}`}
    >
      <FontAwesomeIcon icon={icon} className={classNames(' text-blue-700 bg-blue-50 rounded-lg w-5 h-5 p-2.5')} />
      <div className="ml-3">
        <div className="text-sm	font-semibold	text-gray-900">{name}</div>
        <div className="text-sm text-gray-700">{summary}</div>
      </div>
    </div>
  );
}

PageCard.propTypes = {};

export default memo(PageCard);

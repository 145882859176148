import React, { memo, useMemo, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import pageNotFound from 'images/gif/pageNotFound.json';
import AuthContext from 'contexts/AuthContext';
import { get } from 'lodash';
import { ROLE_EVALUATOR, ROLE_MASTER_RECRUITER, ROLE_RECRUITER } from 'utils/data/roles';
import messages from './messages';
export function NotFound() {
  const { user } = useContext(AuthContext);
  const userRole = useMemo(() => get(user, 'roles'), [user]);

  const handleNavigation = (url) => {
    const isExternal = !url.startsWith(window.location.origin);
    if (!isExternal) {
      window.location.href = url;
    } else {
      window.open(url, '_self');
    }
  };
  const handleClick = () => {
    const isEvaluator = userRole.some((role) => role === ROLE_EVALUATOR);
    const isRecruiter = userRole.some((role) => role === ROLE_MASTER_RECRUITER || role === ROLE_RECRUITER);

    if (isRecruiter) {
      handleNavigation('/workflow');
    } else if (isEvaluator) {
      handleNavigation('/evaluations');
    } else {
      handleNavigation('/workflow');
    }
  };
  return (
    <div className="flex justify-center items-center flex-1 space-y-6 align-center relative">
      <div className="w-[431px] flex flex-col justify-center items-center">
        <div className="w-[96px] items-center">
          <div data-testid="animation">
            <LottiePlayer autoplay loop src={pageNotFound} />
          </div>
        </div>
        <div className="mt-4 text-lg font-semibold text-gray-700">
          <FormattedMessage {...messages.notFound} />
        </div>
        <div className="justify-center items-center w-fit text-base font-normal text-gray-700">
          <FormattedMessage {...messages.pageBody} />
        </div>
        <div className="justify-center items-center w-fit text-base font-normal text-gray-700">
          <FormattedMessage {...messages.check_URL} />{' '}
          <button onClick={handleClick} type="button" className="text-blue-500 font-medium cursor-pointer">
            <FormattedMessage {...messages.homepage} />
          </button>
          .
        </div>
      </div>
    </div>
  );
}

export default memo(NotFound);

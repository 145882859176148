import { triggerToast } from 'components/base/Notification';
import { GLOBAL_ERROR } from 'containers/App/constants';
import { selectStoredInitiatedActionsByResponseId } from 'containers/AppNotifications/selectors';
import { filter, get, includes } from 'lodash';
import { call, all, fork, put, takeEvery, select } from 'redux-saga/effects';
// import { getDescription } from 'utils/data/actions/actionTypes';
// import createEventChannel from 'utils/event';
import { catchError /* getByKey */ } from 'utils/helpers';
// import { processQuery } from 'utils/processQuery';
import {
  ACTION_INITIATED,
  UPDATE_INITIATED_ACTION /* LISTEN_WORKFLOW_ACTIVITIES, STORE_WORKFLOW_ACTIVITIES */,
} from './constants';
// import { SUBSCRIPTION_WORKFLOW_ACTIVITY } from './queries';

// export function* startListeningNotificationsSaga({ client, variables, callback }) {
//   let channel;
//   try {
//     channel = yield call(createEventChannel, {
//       query: processQuery(SUBSCRIPTION_WORKFLOW_ACTIVITY),
//       variables,
//       client,
//       onError: error => {
//         catchError(error);
//         if (callback.onError) {
//           callback.onError(error);
//         }
//       },
//     });
//     while (true) {
//       const data = yield take(channel);
//       const formattedData = yield call(map, data?.data?.wft_workflow_activity, activity => ({
//         ...activity,
//         read: false,
//         pinned: false,
//         deleted: false,
//         ...getDescription(activity),
//       }));
//       const groupedData = yield call(groupBy, formattedData, 'id');
//       yield put({ type: STORE_WORKFLOW_ACTIVITIES, payload: getByKey(groupedData) });
//     }
//   } catch (error) {
//     catchError(error);
//     if (callback.onError) {
//       callback.onError(error);
//     }
//     if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
//       yield put({ type: GLOBAL_ERROR, error });
//     }
//   } finally {
//     if (yield cancelled()) {
//       channel.close();
//     }
//   }
// }

export function* actionInitiatedSaga(props) {
  try {
    const actionType = props?.actionType?.actionType;
    const templates = actionType?.templates;
    const additionalContext = props?.additionalContext;
    const inProgressToastContent = templates?.inProgress ? yield call(templates.inProgress, additionalContext) : null;
    if (inProgressToastContent) {
      yield call(triggerToast, inProgressToastContent);
    }
  } catch (error) {
    yield call(catchError, error);
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export function* updateInitiatedActionSaga(props) {
  try {
    // Todo reload of data
    if (props?.error) {
      throw props?.error;
    }
    if (props?.response) {
      const { response, response_id } = props;
      const successRecords = response?.data
        ? yield call(filter, response.data, (r) => r?.success)
        : yield call(filter, response, (r) => r?.success);
      const errorRecords = response?.data
        ? yield call(filter, response.data, (r) => !r?.success)
        : yield call(filter, response, (r) => !r?.success);
      const successCount = successRecords.length;
      const errorCount = errorRecords.length;
      const action = yield select((state) => selectStoredInitiatedActionsByResponseId(state, response_id));
      const actionType = action?.actionType;
      const templates = actionType?.actionType?.templates;
      const additionalContext = action?.additionalContext;
      const mixPanelAction = actionType?.actionType?.mixPanelAction;
      if (successCount && errorCount) {
        const partialSuccessToastContent = templates?.partialSuccess
          ? yield call(templates.partialSuccess, { errorCount, ...additionalContext })
          : null;
        if (partialSuccessToastContent) {
          yield call(triggerToast, partialSuccessToastContent);
        }
      } else if (successCount) {
        const successToastContent = templates?.success
          ? yield call(templates.success, { successCount, ...additionalContext })
          : null;
        if (successToastContent) {
          yield call(triggerToast, successToastContent);
        }
      } else if (errorCount) {
        const errorToastContent = templates?.error
          ? yield call(templates.error, { errorCount, ...additionalContext })
          : null;
        if (errorToastContent) {
          yield call(triggerToast, errorToastContent);
        }
      } else {
        throw Error('Empty response returned');
      }
      if (mixPanelAction) {
        yield call(mixPanelAction, {
          payload: { successCount: successRecords?.length, errorCount: errorRecords?.length },
        });
      }
    }
  } catch (error) {
    yield call(catchError, error);
    if (includes(get(error, 'message') || '', 'invalid-jwt')) {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export function* actionInitiatedWatcher() {
  yield takeEvery(ACTION_INITIATED, actionInitiatedSaga);
}

export function* updateInitiatedActionWatcher() {
  yield takeEvery(UPDATE_INITIATED_ACTION, updateInitiatedActionSaga);
}

// export function* startListeningNotificationsWatcher() {
//   yield takeLatest(LISTEN_WORKFLOW_ACTIVITIES, startListeningNotificationsSaga);
// }

// Individual exports for testing
export default function* appNotificationsSaga() {
  yield all([fork(actionInitiatedWatcher), fork(updateInitiatedActionWatcher)]);
}

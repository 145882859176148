import { triggerToast } from 'components/base/Notification';
import { ACTION_INITIATED, UPDATE_INITIATED_ACTION } from 'containers/AppNotifications/constants';
import { LOGOUT_COMPLETE } from 'containers/auth/LogoutPage/constants';
import { all, call, cancelled, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { exportToExcel, generateExcelColumns, generateFormattedData } from 'utils/exportToExcel';
import postData from 'utils/postData';
import { GLOBAL_ERROR } from 'containers/App/constants';
import { catchError, getDefaultContext } from 'utils/helpers';
import { find, findIndex, get, includes } from 'lodash';
import Auth from 'utils/auth';
import { END } from 'redux-saga';
import { createApolloSubscriptionChannel } from 'utils/event';
import { processQuery } from 'utils/processQuery';
import { axiosPostData } from 'utils/axiosApi';
import fetchData from 'utils/fetchData';
import * as types from './constants';
import { FETCH_FILE_URL } from './queries';

export const ongoingTasks = [];

export const getClient = async () => {
  const client = await Auth.client();
  return client;
};

export function* stopActionSubscriptionSaga({ response_id }) {
  try {
    const task = yield call(find, ongoingTasks, ['response_id', response_id]);
    if (task) {
      const index = yield call(findIndex, ongoingTasks, ['response_id', response_id]);
      ongoingTasks.splice(index, 1);
      if (task?.channel?.close) {
        yield task.channel.close();
      }
    }
  } catch (error) {
    yield call(catchError, error);
  }
}

export function* handleActionResponseSaga({ response, emit, response_id }) {
  try {
    const output = yield call(get, response, 'output');
    const errors = yield call(get, response, 'errors');
    if (output || errors) {
      emit(END);
      yield put({ type: UPDATE_INITIATED_ACTION, response_id, response: output, error: errors });
      yield call(stopActionSubscriptionSaga, { response_id });
    }
  } catch (error) {
    if (emit) {
      emit(END);
    }
    yield call(catchError, error);
  }
}

export function* stopAllActionSubscriptionSaga() {
  try {
    if (ongoingTasks && ongoingTasks.length) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < ongoingTasks.length; index++) {
        const task = yield call(get, ongoingTasks, index);
        if (task && task?.channel?.close) {
          yield task.channel.close();
        }
      }
      ongoingTasks.length = 0;
    }
  } catch (error) {
    yield call(catchError, error);
  }
}

export function* listenActionResponse({ responseQuery, response_id, key, type, ...props }) {
  const client = yield call(getClient);
  const context = yield call(getDefaultContext, Auth.graphToken);
  const query = yield call(processQuery, responseQuery);
  const variables = {
    id: response_id,
  };
  yield put({
    type: ACTION_INITIATED,
    response_id,
    key,
    responseQuery,
    ...props,
  });
  const channel = yield call(createApolloSubscriptionChannel, { client, query, variables, context });
  ongoingTasks.push({ response_id, channel });
  try {
    while (true) {
      const { data, emit, error } = yield take(channel);
      if (error) {
        emit(END);
        throw error;
      }
      const result = get(data?.data, key);
      yield call(handleActionResponseSaga, { response: result, emit, response_id, ...props });
    }
  } catch (error) {
    yield call(catchError, error);
    if (includes(get(error, 'message') || '', 'invalid-jwt')) {
      yield call(stopAllActionSubscriptionSaga);
      yield put({ type: GLOBAL_ERROR, error });
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

export function* actionReceiverSaga(props) {
  const {
    payload,
    callback,
    queryString,
    key,
    refetchActions,
    responseQuery,
    mixPanelAction,
    spreadPayload = false,
  } = props;
  try {
    if (!queryString) {
      throw Error('queryString not provided');
    }
    if (mixPanelAction) {
      yield call(mixPanelAction, { options: props });
    }
    const response = yield call(postData, {
      queryString,
      payload,
      spreadPayload,
    });
    const responseData = yield call(get, response?.data, key);
    if (responseQuery) {
      if (callback?.onSuccess) {
        yield call(callback.onSuccess);
      }
      yield call(listenActionResponse, { ...props, response_id: responseData });
    } else if (key && response && response.data && get(response.data, key)) {
      if (get(response.data, `${key}.error`)) {
        throw Error(get(response.data, `${key}.error`));
      }
      // if (get(response.data, `${key}.failure_count`)) {
      //   throw Error(`Having failure count: ${get(response.data, `${key}.failure_count`)}`);
      // }
      if (get(response.data, `${key}.error_count`)) {
        if (get(response.data, `${key}.message`)) {
          throw Error(get(response.data, `${key}.message`));
        } else {
          throw Error(`Having failure count: ${get(response.data, `${key}.error_count`)}`);
        }
      }
      if (responseData && Array.isArray(responseData) && responseData.length === 1 && responseData[0]?.error_message) {
        throw Error(responseData[0]?.error_message);
      }
      if (callback?.onSuccess) {
        yield call(callback.onSuccess, key && get(response?.data, key) ? get(response?.data, key) : response);
      }
      if (refetchActions && Array.isArray(refetchActions) && refetchActions?.length) {
        yield all(refetchActions.map((action) => put(action({ forceRefresh: true }))));
      }
    }
  } catch (error) {
    yield call(catchError, error, true);
    if (callback && callback.onError) {
      yield call(callback.onError, error);
    }
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export function* exportDataWorker({ payload = { columns: [], rows: [], name: '' } }) {
  try {
    const formattedRows = yield call(generateFormattedData, payload.columns, payload.rows);
    const formattedColumns = yield call(generateExcelColumns, payload.columns);
    yield call(exportToExcel, { rows: formattedRows, columns: formattedColumns, name: payload.name });
    yield call(triggerToast, {
      variant: 'success',
      message: {
        title: 'Export completed successfully',
        summary: 'Your requested report has been downloaded successfully',
      },
    });
  } catch (error) {
    yield call(catchError, error, true);
    yield call(triggerToast, {
      variant: 'danger',
      message: {
        title: 'Export has failed',
        summary: `The download of the report you requested was unsuccessful. ${error.message}`,
      },
    });
  }
}

export function* uploadFileSaga(props) {
  const { payload, queryString, callback } = props;

  try {
    const uploadFilePayload = {
      file_type_id: payload?.additionalParameter?.file_type_id,
      original_name: payload?.file?.name,
      owner_id: payload?.additionalParameter?.owner_id,
      extension: payload?.file.name?.split('.').pop(),
    };

    const response = yield call(postData, {
      queryString,
      payload: uploadFilePayload,
      spreadPayload: true,
    });

    if (response && response?.data?.file_upload_file) {
      const fileUploadData = response?.data?.file_upload_file?.data;
      const URL = response?.data?.file_upload_file?.data?.url;
      const { url, ...newFileUploadData } = fileUploadData;

      const formData = new FormData();
      Object.keys(newFileUploadData).forEach((key) => {
        formData.append(key, newFileUploadData[key]);
      });
      formData.append('file', payload?.file);
      yield call(axiosPostData, URL, formData);

      if (callback?.onSuccess && response?.data) {
        yield call(callback?.onSuccess, response?.data);
      }
    } else {
      throw Error('No Response from file upload api');
    }
  } catch (error) {
    yield call(catchError, error, true);
    if (callback && callback?.onError) {
      yield call(callback?.onError, error);
    }
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export function* fetchFileUrlSaga(props) {
  const { queryVariables, callback } = props;

  try {
    const response = yield call(fetchData, {
      queryString: FETCH_FILE_URL,
      queryVariables,
      queryKey: 'file_download_file',
    });

    if (response && response?.resource_url && callback?.onSuccess) {
      yield call(callback?.onSuccess, response);
    }
  } catch (error) {
    yield call(catchError, error, true);
    if (callback && callback?.onError) {
      yield call(callback?.onError, error);
    }
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export function* uploadFileWatcher() {
  yield takeLatest(types.UPLOAD_FILE, uploadFileSaga);
}

export function* exportDataWatcher() {
  yield takeLatest(types.EXPORT_DATA, exportDataWorker);
}

export function* fetchFileUrlWatcher() {
  yield takeEvery(types.FETCH_FILE_URL, fetchFileUrlSaga);
}

export function* actionWatcher() {
  yield takeLatest(
    [
      types.INVITE_CANDIDATE,
      types.REINVITE_CANDIDATE,
      types.SCHEDULE_INTERVIEW,
      types.BULK_INVITE_CANDIDATES,
      types.UPDATE_SCHEDULE_INTERVIEWER,
      types.RESCHEDULE_INTERVIEW,
      types.REMIND_CANDIDATE,
      types.ASSIGN_PANEL,
      types.EXTEND_LAST_DATE,
      types.CANCEL_INTERVIEW,
      types.UPDATE_STATUS,
      types.CREATE_DRIVE,
      types.EDIT_DRIVE,
      types.CANCEL_DRIVE,
      types.ADD_INTERVIEWER_TO_ROOM,
      types.REMOVE_INTERVIEWER_FROM_ROOM,
      types.ADD_ROOM,
      types.REMIND_CANDIDATE_INTERVIEW,
      types.CANCEL_CANDIDATE_INTERVIEW,
      types.EDIT_DRIVE_OCCURRENCE,
      types.CANCEL_DRIVE_OCCURRENCE,
      types.REMOVE_ROOM_FROM_DRIVE_OCCURRENCE,
      types.REPORT_ISSUE,
      types.FEATURE_REQUEST,
      types.UPDATE_USER,
      types.UPDATE_PASSWORD,
      types.UPDATE_COLLABORATOR,
      types.CREATE_WORKFLOW,
      types.DECLINE_INTERVIEW,
      types.PROPOSE_NEW_TIME,
      types.ACCEPT_PROPOSED_NEW_TIME,
      types.DECLINE_PROPOSED_NEW_TIME,
      types.DELETE_AVAILABILITY,
      types.ADD_AVAILABILITY,
      types.UPDATE_MEETING_PARTICIPATION_STATUS,
      types.COMPLETE_MEETING,
      types.ADD_SCH_OVERRIDE,
      types.UPDATE_DRIVE_MEETING_PARTICIPATION_STATUS,
      types.UPDATE_DRIVE_MEMBER_STATUS,
      types.DELETE_SCHEDULE_OVERRIDE,
      types.UPDATE_CANDIDATE_STEP,
      types.RESCHEDULE_MEETING,
      types.GENERATE_VOICE_TOKEN,
      types.ASSIGN_EVALUATOR,
      types.CONFIRM_SLOT_BOOKING,
      types.SUBMIT_EVALUATION,
      types.CONFIRM_INTERVIEW,
      types.RESPOND_NOTIFICATION,
    ],
    actionReceiverSaga,
  );
}

export function* stopTasksOnLogoutWatcher() {
  yield takeLatest(LOGOUT_COMPLETE, stopAllActionSubscriptionSaga);
}

// Individual exports for testing
export default function* actionSaga() {
  yield all([
    fork(actionWatcher),
    fork(stopTasksOnLogoutWatcher),
    fork(exportDataWatcher),
    fork(uploadFileWatcher),
    fork(fetchFileUrlWatcher),
  ]);
}

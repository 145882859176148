/*
 * GrowthBookError Messages
 *
 * This contains all the text for the GrowthBookError component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.GrowthBookError';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the GrowthBookError component!',
  },
  loadingError: {
    id: `${scope}.loadingError`,
    defaultMessage: 'Loading Error',
  },
  refresh: {
    id: `${scope}.refresh`,
    defaultMessage: 'Refresh',
  },
  pageBodyMessage: {
    id: `${scope}.pageBodyMessage`,
    defaultMessage: 'We encountered an issue while loading the application. Please click the button below to retry.',
  },
});

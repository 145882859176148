import { createSelector } from 'reselect';
import { selectMetaData } from '../App/selectors';
import { initialState } from './reducer';

/**
 * Direct selector to the action state domain
 */

const selectActionDomain = (state) => state.action || initialState;

/**
 * Other specific selectors
 */

const selectAction = createSelector(selectActionDomain, (state) => state);

/**
 * Default selector used by Action
 */

const selectProviders = createSelector(selectMetaData, (state) => state.providers);
const selectTimezones = createSelector(selectMetaData, (state) => state.timezones);

export { selectActionDomain, selectAction, selectProviders, selectTimezones };

/**
 *
 * TwAvatar
 *
 */

import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import getDisplayName from 'utils/getDisplayName';
import { get } from 'lodash';
import Tooltip from './AvatarTooltip';

const sizes = {
  xxs: {
    image: 'h-5 w-5 min-w-[20px]',
    text: 'text-[10px]',
  },
  xs: {
    image: 'h-6 w-6 min-w-[1.5rem]',
    text: 'text-xs',
  },
  sm: {
    image: 'h-8 w-8 min-w-[2rem]',
    text: 'text-sm',
  },
  md: {
    image: 'h-10 w-10 min-w-[2.5rem]',
    text: 'font-medium',
  },
  lg: {
    image: 'h-12 w-12 min-w-[3rem]',
    text: 'text-lg',
  },
  xl: {
    image: 'h-14 w-14 min-w-[3.5rem]',
    text: 'text-xl',
  },
  '5xl': {
    image: 'h-20 w-20 min-w-[3.5rem]',
    text: 'text-2xl',
  },
};

const Avatar = (props) => {
  const { size, data, isInitials, customInitial, showToolTip, role = 'avatar', showRing, variant } = props;
  const fullName = useMemo(() => (data ? getDisplayName(data) : ''), [data]);
  const [anchor, setAnchor] = useState(null);
  const [tooltip, toggleTooltip] = useState(false);

  const initialContent = useMemo(() => {
    if (customInitial) {
      return customInitial;
    }
    if (fullName && fullName.length) {
      const names = fullName.split(' ');
      return names
        .slice(0, 2)
        .map((d) => d.substring(0, 1))
        .join('');
    }
    return ((data && data.id) || '').toString().substring(0, 2);
  });

  const displayPicture = useMemo(() => (data ? data.profile_pic : ''), [data]);

  if (isInitials || !displayPicture || customInitial) {
    return (
      <div
        ref={setAnchor}
        onMouseEnter={() => toggleTooltip(() => true)}
        onMouseLeave={() => toggleTooltip(() => false)}
      >
        <div
          role={role}
          className={classNames(
            variant ? get(variant, 'light') : 'bg-teal-400',
            'flex items-center justify-center rounded-full',
            sizes[size].image,
            showRing && 'ring-1 ring-white',
          )}
        >
          <span className={classNames('leading-non text-white font-medium', sizes[size].text)}>{initialContent}</span>
        </div>
        {showToolTip && tooltip ? (
          <Tooltip id={`tooltip-${data?.id}`} anchor={anchor} fullName={fullName} email={data.email} />
        ) : (
          <></>
        )}
      </div>
    );
  }

  return (
    <span
      ref={setAnchor}
      onMouseEnter={() => toggleTooltip(() => true)}
      onMouseLeave={() => toggleTooltip(() => false)}
    >
      <img
        role={role}
        className={classNames('inline-block rounded-full', sizes[size].image, showRing && 'ring-1 ring-white')}
        src={displayPicture}
        alt=""
      />
      {showToolTip && tooltip ? (
        <Tooltip id={`tooltip-${data?.id}`} anchor={anchor} fullName={fullName} email={data.email} />
      ) : (
        <></>
      )}
    </span>
  );
};

Avatar.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    external_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    email: PropTypes.string,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    middle_name: PropTypes.string,
    profile_pic: PropTypes.string,
  }),
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl', '5xl']),
  isInitials: PropTypes.bool,
  customInitial: PropTypes.string,
  showToolTip: PropTypes.bool,
  showRing: PropTypes.bool,
};

Avatar.defaultProps = {
  data: {},
  size: 'sm',
  isInitials: false,
  customInitial: '',
  showToolTip: false,
};

export default Avatar;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Content from './Content';

const ChangeLanguage = (props) => {
  const { handleClose } = props;

  return (
    <div className="p-6">
      <div className="pb-6 font-medium text-base leading-5 text-gray-900">
        <FormattedMessage {...messages.header} />
      </div>
      <Content handleClose={handleClose} />
    </div>
  );
};

export default ChangeLanguage;

import { GrowthBook } from '@growthbook/growthbook-react';
import Mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';
import { axiosGetData } from './axiosApi';
import { promiseDelay } from './helpers';
import { appConstants } from './appConstants';

let proxy = false;

export const getGrowthBookSecrets = () =>
  proxy
    ? { apiHost: appConstants.PROXY_GROWTHBOOK_API_HOST, clientKey: appConstants.PROXY_GROWTHBOOK_CLIENT_KEY }
    : { apiHost: appConstants.GROWTHBOOK_API_HOST, clientKey: appConstants.GROWTHBOOK_CLIENT_KEY };

export const pingGrowthBook = async () => {
  const url = `${getGrowthBookSecrets().apiHost}/api/features/${getGrowthBookSecrets().clientKey}`;
  return axiosGetData(url);
};

let count = 0;

const init = () =>
  new GrowthBook({
    apiHost: getGrowthBookSecrets().apiHost,
    clientKey: getGrowthBookSecrets().clientKey,
    enableDevMode: appConstants.NODE_ENV !== 'production',
    subscribeToChanges: true,
    trackingCallback(experiment, result) {
      Mixpanel.track(appConstants.GROWTHBOOK_EXPERIMENT_EVENT, {
        'Experiment name': experiment.key,
        'Variant name': result.variationId,
        $source: 'growthbook',
      });
    },
  });

export const initializeGrowthBook = async () => {
  try {
    await pingGrowthBook();
    const growthBook = init();

    const resp = await growthBook.init({
      streaming: true,
      cacheSettings: {
        backgroundSync: true,
        cacheKey: 'gbFeaturesCache',
        staleTTL: 1000 * 60,
        maxAge: 0,
        maxEntries: 1,
        disableIdleStreams: false,
        idleStreamInterval: 20000,
        disableCache: true,
      },
    });
    if (resp?.success === false) {
      throw Error(resp?.error?.message);
    }
    return growthBook;
  } catch (e) {
    if (count < 3) {
      count += 1;
      await promiseDelay(3000);
      return initializeGrowthBook();
    }
    if (!proxy) {
      const error = new Error(
        [
          `Failed to initialize GrowthBook with the domain ${appConstants.GROWTHBOOK_API_HOST}`,
          e,
          'Initiating using proxy domain.',
        ].join('. '),
      );
      Sentry.captureException(error);
      proxy = true;
      count = 0;
      return initializeGrowthBook();
    }
    const error = new Error(`Failed to initialize GrowthBook.  + ${e}. Domain: ${getGrowthBookSecrets().apiHost}`);
    Sentry.captureException(error);
    throw error;
  }
};

import Tooltip from 'components/base/Tooltip';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import getDisplayName from 'utils/getDisplayName';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar/NewAvatar';

const UserDetails = (props) => {
  const {
    data: userData,
    size,
    border,
    contentAlign,
    hideEmail,
    hideImage,
    hidePhone,
    avatarProps,
    noWrapper,
    nameTextColor,
    showToolTip,
    additionalContent,
    isNameClickable,
    isTitleView,
  } = props;

  const data = useMemo(() => userData || {}, [userData]);
  const [anchor, setAnchor] = useState(null);
  const [tooltip, toggleTooltip] = useState(false);
  const fullName = useMemo(() => getDisplayName(data), [data]);

  const wrapperSizes = useMemo(
    () => ({
      xs: 'px-2 py-1',
      sm: 'px-3 py-2',
      md: 'px-4 py-3',
      lg: 'px-6 py-5',
    }),
    [],
  );
  const wrapperTextColor = useMemo(
    () => ({
      greenText: 'text-teal-400',
      default: 'text-gray-600',
    }),
    [],
  );

  const sizes = useMemo(
    () => ({
      xs: {
        wrapper: 'space-x-2',
        title: 'text-xs',
        description: 'text-xs',
      },
      sm: {
        wrapper: 'space-x-3',
        title: 'text-sm',
        description: 'text-xs',
      },
      md: {
        wrapper: 'space-x-4',
        title: 'text-base',
        description: 'text-sm',
      },
      lg: {
        wrapper: 'space-x-5',
        title: 'text-lg',
        description: 'text-base',
      },
    }),
    [],
  );

  const borders = useMemo(
    () => ({
      'border-none': '',
      default: 'border border-gray-300 shadow-sm',
      'border-round': 'border border-gray-300 shadow-sm rounded-lg',
    }),
    [],
  );

  const align = useMemo(() => ({
    inline: 'flex-row',
    default: 'flex-col',
  }));

  if (!userData) {
    return <div>-</div>;
  }

  return (
    <div
      className={classNames(
        'flex flex-1 items-center',
        noWrapper ? '' : wrapperSizes[size] || wrapperSizes.sm,
        sizes[size]?.wrapper || sizes.sm.wrapper,
        borders[border],
      )}
    >
      {hideImage ? <></> : <Avatar data={data} size={size} {...avatarProps} />}
      <div
        ref={setAnchor}
        onMouseEnter={() => toggleTooltip(() => true)}
        onMouseLeave={() => toggleTooltip(() => false)}
        className={classNames('flex flex-1 w-0', align[contentAlign || 'default'])}
      >
        <p
          className={classNames(
            contentAlign === 'inline' ? 'mr-2' : '',
            isTitleView ? 'font-medium text-gray-900' : `truncate ${wrapperTextColor[nameTextColor || 'default']}`,
          )}
        >
          <span
            className={classNames(
              sizes[size]?.title || sizes.sm.title,
              isNameClickable && 'text-accent-700 font-medium cursor-pointer',
            )}
          >
            {fullName}
          </span>
        </p>
        {hideEmail ? (
          <></>
        ) : (
          <p
            className={classNames(
              'truncate text-primary-400',
              sizes[size]?.description || sizes.sm.description,
              contentAlign === 'inline' ? 'leading-7' : '',
            )}
          >
            {data.email}
          </p>
        )}
        {hidePhone ? (
          <></>
        ) : (
          <p
            className={classNames(
              'truncate text-primary-400',
              sizes[size]?.description || sizes.sm.description,
              contentAlign === 'inline' ? 'leading-7' : '',
            )}
          >
            {data.phone_number}
          </p>
        )}
        {additionalContent ? (
          <p
            className={classNames(
              'truncate',
              sizes[size]?.description || sizes.sm.description,
              contentAlign === 'inline' ? 'leading-7' : '',
              isTitleView ? 'font-normal text-sm text-gray-600' : 'text-primary-400',
            )}
          >
            {additionalContent}
          </p>
        ) : (
          <></>
        )}
        {showToolTip && tooltip ? (
          <Tooltip id={`tooltip-${data?.id}`} anchor={anchor} placement="bottom" offsetValue={10}>
            <div className={classNames('px-2 py-1 bg-gray-900 text-white text-[10px] rounded')}>
              <div>{fullName}</div>
              <div>{data?.email}</div>
            </div>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

UserDetails.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    external_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    email: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    middle_name: PropTypes.string,
    profile_pic: PropTypes.string,
  }),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  border: PropTypes.oneOf(['border-none', 'default', 'border-round']),
  hideImage: PropTypes.bool,
  hideEmail: PropTypes.bool,
  hidePhone: PropTypes.bool,
  contentAlign: PropTypes.oneOf(['inline', 'default']),
  nameTextColor: PropTypes.oneOf(['greenText', 'default']),
  noWrapper: PropTypes.bool,
  avatarProps: PropTypes.shape({
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    shape: PropTypes.oneOf(['rounded-full', 'rounded-md']),
    background: PropTypes.string,
    isInitials: PropTypes.bool,
    textColor: PropTypes.string,
  }),
  showToolTip: PropTypes.bool,
  additionalContent: PropTypes.any,
  isNameClickable: PropTypes.bool,
};

UserDetails.defaultProps = {
  data: {},
  size: 'sm',
  border: 'border-round',
  hideImage: false,
  hideEmail: false,
  hidePhone: true,
  noWrapper: false,
  avatarProps: {
    isInitials: false,
  },
  showToolTip: false,
  isNameClickable: false,
};

export default UserDetails;

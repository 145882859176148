import React from 'react';
import { map } from 'lodash';

import PageCard from '../PageCard';

function Content({ apps, handleClick }) {
  return (
    <div className="w-96 p-2 border rounded" data-testid="pageCardContent">
      {map(apps, (app) => (
        <PageCard
          key={app.route}
          icon={app.icon}
          name={app.name}
          summary={app.summary}
          route={app.route}
          onClick={handleClick}
        />
      ))}
    </div>
  );
}

export default Content;

import { eventChannel, END } from 'redux-saga';
import * as Sentry from '@sentry/react';
import { catchError } from './helpers';

export const createEventChannel = ({ client, query, variables, onError }) =>
  eventChannel((emitter) => {
    try {
      const observable = client.subscribe({
        query,
        fetchPolicy: 'cache-and-network',
        variables,
      });

      const subscription = observable.subscribe({
        next({ data }) {
          emitter({ data });
        },
        error(err) {
          Sentry.captureException(err);
          if (onError) {
            onError(err);
          }
          emitter(END);
        },
      });
      return () => subscription.unsubscribe();
    } catch (error) {
      emitter(END);
      Sentry.captureException(error);
      if (onError) {
        onError(error);
      }
      return () => {};
    }
  });

export default createEventChannel;

// Function to create an event channel for Apollo Client subscription
export function createApolloSubscriptionChannel({ client, query, variables, context }) {
  return eventChannel((emit) => {
    const subscription = client
      .subscribe({
        query,
        variables,
        context: {
          ...context,
        },
      })
      .subscribe({
        next: (data) => emit({ data, emit }),
        error: (error) => {
          catchError(error, true);
          emit({ error, emit });
        },
      });
    // Return the unsubscribe function
    return () => {
      subscription.unsubscribe();
    };
  });
}

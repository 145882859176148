import { DateTime } from 'luxon';
import { map, get, flatMap, uniqBy, filter, split, capitalize } from 'lodash';
import { parseExcelDate, parseExcelTime } from 'utils/date';
import { parseCandidates, parseEvaluations } from 'utils/helpers';
import { v4 as uuidv4 } from 'uuid';

export const constructSlot = ({ id = null, candidate, evaluators, startDate, duration }, index = 0) => {
  const startAt = DateTime.fromISO(startDate);
  return {
    id: id || (process.env.NODE_ENV === 'test' ? index : uuidv4()),
    candidate,
    evaluators,
    startDate: startAt.toISO(),
    endDate: startAt.plus({ minutes: duration.value }).toISO(),
  };
};

export const getEvaluators = (evaluations, candidate) => {
  const wse = filter(
    evaluations,
    (data) => get(data, 'workflow_candidate_step.workflowCandidate.candidate_id') === candidate?.id,
  );
  const panel = flatMap(map(wse, (e) => e.panel));
  return uniqBy(panel, 'id');
};

export const getInitialSlots = ({ data, selectedDuration }) =>
  map(data, (d, index) =>
    constructSlot(
      {
        candidate: d?.candidate,
        evaluators: d?.evaluators,
        startDate: null,
        duration: selectedDuration,
      },
      index,
    ),
  );

export const getStartDate = (date, time) => {
  try {
    if (date && time) {
      const parseDate = date ? parseExcelDate(date) : null;
      const parseTime = time ? parseExcelTime(time) : null;
      const value = parseDate && parseTime ? DateTime.fromISO(`${parseDate}T${parseTime}`) : null;
      return value && value?.isValid ? value.toISO() : null;
    }
    return null;
  } catch {
    return null;
  }
};

export const parseExcelEvaluators = (data, allEvaluators) => {
  if (data && data?.evaluators && (data?.evaluators || '').trim()) {
    const evaluators = split(data?.evaluators, ',');
    if (evaluators && Array.isArray(evaluators) && evaluators.length) {
      const formattedEvaluators = map(evaluators, (evaluator) => {
        const user =
          allEvaluators && Object.keys(allEvaluators).length && allEvaluators[(evaluator || '').trim()]
            ? allEvaluators[(evaluator || '').trim()]
            : null;
        return user;
      });
      return uniqBy(
        filter(formattedEvaluators, (d) => d),
        'email',
      );
    }
    return [];
  }
  return [];
};

export const parseSelectedData = (selectedData) => {
  if (selectedData && Array.isArray(selectedData) && selectedData.length) {
    const data = map(selectedData, (d) => {
      const candidates = parseCandidates([d]);
      if (candidates && candidates.length) {
        const evaluations = parseEvaluations([d]);
        return {
          candidate: candidates[0],
          evaluators: getEvaluators(evaluations, candidates[0]),
        };
      }
      return null;
    });
    return filter(data, (d) => d);
  }
  return [];
};

export const formatProviders = (data) =>
  data &&
  data.map((item, key) => ({
    id: key + 1,
    value: item?.service_type,
    label: capitalize(item?.name),
  }));

// export const slotOptionsWithTimeValidation = slot =>
//   slot?.evaluators.length ? !(slot?.startDate && slot?.endDate) : false;

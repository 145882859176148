/**
 *
 * GrowthBookError
 *
 */

import React, { memo } from 'react';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import pageNotFound from 'images/gif/pageNotFound.json';

import { FormattedMessage } from 'react-intl';
import { Button } from 'components/base';
import messages from './messages';

export function GrowthBookError({ errorMessage }) {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="flex justify-center items-center flex-1 space-y-6 align-center relative">
      <div className="w-[431px] flex flex-col justify-center items-center">
        <div className="w-[96px] items-center">
          <div data-testid="animation">
            <LottiePlayer autoplay loop src={pageNotFound} />
          </div>
        </div>
        <div className="mt-4 text-lg font-semibold text-gray-700">
          <FormattedMessage {...messages.loadingError} />
        </div>
        <div className="justify-center items-center w-fit text-base font-normal text-gray-700">{errorMessage}</div>
        <div className="justify-center items-center w-fit text-base font-normal text-gray-700 text-center">
          <FormattedMessage {...messages.pageBodyMessage} />
        </div>
        <div className="mt-2">
          <Button onClick={handleRefresh}>
            <FormattedMessage {...messages.refresh} />
          </Button>
        </div>
      </div>
    </div>
  );
}

GrowthBookError.propTypes = {};

export default memo(GrowthBookError);

/*
 *
 * AuthContext
 *
 */
import { useFeatureIsOn, useGrowthBook } from '@growthbook/growthbook-react';
import Mixpanel from 'mixpanel-browser';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAppState, selectUser } from 'containers/App/selectors';
import { growthBookFeatureFlags } from 'utils/data/featureFlags';

const AuthContext = React.createContext({
  user: {},
  isAuthenticated: false,
  isAuthenticating: true,
});

const isDevelopment = process.env.NODE_ENV === 'development' || process.env.APP_ENV === 'local';

function AuthProvider(props) {
  const user = useSelector(selectUser);
  const appState = useSelector(selectAppState);
  const isAuthenticated = useMemo(() => (isAuthenticating ? false : !!(user && user?.id && user?.isLoggedIn)), [user]);
  const [isAuthenticating, toggleAuthenticating] = useState(true);
  const growthBook = useGrowthBook();
  const mixPanelEnabled = useFeatureIsOn(growthBookFeatureFlags.MIX_PANEL_ENABLED);

  const [enableMixPanel, setEnableMixPanel] = useState(mixPanelEnabled);

  if (mixPanelEnabled !== enableMixPanel && !isDevelopment) {
    setEnableMixPanel(mixPanelEnabled);
  }

  const value = useMemo(
    () => ({
      user: { ...user, timezone: appState?.timezone },
      isAuthenticated,
      isAuthenticating,
      tenant_id: user?.tenant?.id,
      old_id: user?.old_id,
    }),
    [user, isAuthenticated, isAuthenticating, appState?.timezone],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      toggleAuthenticating(false);
    }, 5000);
    if (user && user?.id && user?.isLoggedIn && !appState?.loading) {
      clearTimeout(timer);
      toggleAuthenticating(false);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [user, user?.id, appState?.loading]);

  useEffect(() => {
    Mixpanel.init(process.env.MIXPANEL_PROJECT_TOKEN, {
      ignore_dnt: true,
      debug: false,
      opt_out_tracking_by_default: !enableMixPanel,
      track_pageview: false,
      loaded(mixpanel) {
        growthBook.setAttributes({
          ...growthBook.getAttributes(),
          id: mixpanel.get_distinct_id(),
        });
      },
    });
    if (enableMixPanel) {
      Mixpanel.opt_in_tracking();
    } else {
      Mixpanel.opt_out_tracking();
    }
  }, [enableMixPanel]);

  useEffect(() => {
    if (isAuthenticated && user && Object.keys(user).length) {
      growthBook.setAttributes({
        ...growthBook.getAttributes(),
        user_id: user.id || user.email || user.uid,
        email: user.email || user.userName,
        country: user.country,
        tenant_id: user?.organization || user?.tenant?.id,
        loggedIn: true,
      });
      Mixpanel.identify(user.id || user.email || user.uid);
    }
  }, [isAuthenticated, user]);

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

export const AuthConsumer = AuthContext.Consumer;

export { AuthProvider };

export default AuthContext;

import React, { useContext, useRef, useState } from 'react';
import ActionContext from 'contexts/ActionContext';
import { FormattedMessage } from 'react-intl';
import { topics } from './topics';
import { SidePanel } from '../../SidePanel';
import messages from './messages';
import Content from './Content';
import SuccessScreen from './SuccessScreen';

const RequestFeature = (props) => {
  const { open = true, handleClose } = props;
  const initialFocus = useRef(null);
  const [topic, setTopic] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const { action, onSubmit, showNotification } = useContext(ActionContext);
  const [loading, toggleLoading] = useState(false);
  const [successModal, showSuccessModal] = useState(false);

  const onClose = () => {
    if (!loading && handleClose) {
      handleClose();
    }
  };

  const handleChange = (e) => {
    setSuggestion(e.target.value);
  };

  const handleSubmit = () => {
    const payload = {
      title: topic?.value,
      content: suggestion,
    };

    toggleLoading(true);
    onSubmit({
      payload,
      action: action.featureRequest,
      callback: {
        onSuccess: () => {
          toggleLoading(false);
          showSuccessModal(true);
          showNotification({
            variant: 'success',
            title: <FormattedMessage {...messages.success_title} />,
            summary: <FormattedMessage {...messages.success_summary} />,
          });
        },
        onError: (error) => {
          toggleLoading(false);
          showNotification({
            variant: 'danger',
            title: <FormattedMessage {...messages.error_message_title} />,
            summary: (
              <>
                <FormattedMessage {...messages.error_message_summary} />, <span>{error?.message}</span>{' '}
              </>
            ),
          });
        },
      },
    });
    return true;
  };

  return (
    <>
      <SidePanel show={open} backgroundOverlay customHeader initialFocus={initialFocus} onClose={onClose}>
        <Content
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          topics={topics}
          topic={topic}
          setTopic={setTopic}
          loading={loading}
          toggleLoading={toggleLoading}
          suggestion={suggestion}
          onClose={onClose}
        />
      </SidePanel>
      {successModal && <SuccessScreen onClose={onClose} />}
    </>
  );
};

export default RequestFeature;

import { find, map } from 'lodash';
import { DateTime } from 'luxon';
import { getCurrentDate, parseDateTime } from 'utils/helpers';

export const getDefaultTimezone = (timezones = []) => {
  const { zoneName } = DateTime.local();
  const ist = { description: 'Indian Standard Time', value: 'Asia/Kolkata' };
  if ((zoneName || '').toLowerCase().includes('calcutta') || (zoneName || '').toLowerCase().includes('Kolkata')) {
    return ist;
  }
  return (
    find(timezones, ['value', zoneName]) || {
      description: zoneName,
      value: zoneName,
    }
  );
};

export const getFormattedTimeWithDate = (timeString) => {
  const time = parseDateTime(timeString);
  return getCurrentDate().startOf('day').plus({ hours: time.hour, minutes: time.minute });
};

export const getFormattedAvailability = (availabilities) =>
  map(availabilities, (availability) => {
    const parsedDate = getFormattedTimeWithDate(availability.from);
    return {
      ...availability,
      start_time: parsedDate.toFormat('hh:mm a'),
      end_time: getFormattedTimeWithDate(availability.to).toFormat('hh:mm a'),
      sortOrder: +parsedDate,
      totalMinutes: parsedDate.hour * 60 + parsedDate.minute,
    };
  });

export const getFormattedOverrides = (overrides) =>
  map(overrides, (override) => {
    const parsedDate = parseDateTime(override.from_date);
    return {
      ...override,
      start_time: parsedDate.toFormat('hh:mm a'),
      end_time: parseDateTime(override.to_date).toFormat('hh:mm a'),
      sortOrder: +parsedDate,
      date: parsedDate.startOf('day').toUTC().toISO(),
    };
  });

import { useState, useEffect, useContext } from 'react';
import ActionContext from 'contexts/ActionContext';

const useFetchFileUrl = (profile_pic_id) => {
  const [url, setUrl] = useState(null);
  const { action, dispatch } = useContext(ActionContext);

  useEffect(() => {
    if (profile_pic_id) {
      const queryVariables = { id: profile_pic_id };
      dispatch(
        action.fetchFileUrl({
          queryVariables,
          callback: {
            onSuccess: (res) => {
              setUrl(() => res?.resource_url);
            },
          },
        }),
      );
    }
  }, [profile_pic_id]);

  return { url };
};

export default useFetchFileUrl;

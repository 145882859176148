/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import { produce } from 'immer';
import { concat, set, remove } from 'lodash';
import { setItem } from 'utils/state';
import {
  GLOBAL_ERROR,
  GLOBAL_LOADING,
  INIT_CLIENT,
  CLEAR_ERROR,
  SAVE_STATE_ACTION,
  STOPPED_TASK,
  SET_TASK_ACTIVE,
  INIT_COMPLETE,
  STORE_USER_DATA,
  STORE_META_DATA,
  STORE_COUNTRY,
  UPDATE_TIME_ZONE,
} from './constants';

// The initial state of the App
export const initialState = {
  loading: true,
  error: null,
  client: {},
  user: {},
  hasClient: false,
  meta: {},
  country: {},
  timezone: null,
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GLOBAL_ERROR:
        draft.loading = false;
        draft.error = action.error || action.e;
        break;
      case INIT_CLIENT:
        draft.env = { ...draft.env, ...{ vw: action.vw, vh: action.vh } };
        draft.loading = true;
        break;
      case INIT_COMPLETE:
        draft.loading = false;
        draft.client = action.client;
        draft.user = action.user;
        break;
      case GLOBAL_LOADING:
        draft.loading = true;
        break;
      case CLEAR_ERROR:
        draft.error = null;
        break;
      case STORE_USER_DATA: {
        draft.user = {
          ...draft.user,
          ...action?.data,
        };
        break;
      }
      case STORE_META_DATA: {
        if (action?.payload && Object.keys(action?.payload).length) {
          Object.keys(action?.payload).forEach((key) => {
            draft.meta[key] = action?.payload[key];
          });
        }
        break;
      }
      case STORE_COUNTRY: {
        draft.country = {
          ...action?.data,
        };
        break;
      }
      case UPDATE_TIME_ZONE: {
        draft.timezone = action?.timezone;
        break;
      }
    }
  });
export const savedReducer = (state = {}, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SAVE_STATE_ACTION:
        set(draft, `${action.key}`, action.data);
        setItem(action.key, action.data);
        break;
    }
  });

export const taskReducer = (state = [], action) => {
  switch (action.type) {
    case SET_TASK_ACTIVE:
      state = concat(state, [action.task]);
      break;
    case STOPPED_TASK:
      state = remove(state, (t) => t.id === action.task.id);
      break;
  }
  return state;
};

export default appReducer;

/*
 *
 * Action actions
 *
 */

import { fetchAvailabilities } from 'containers/InterviewerAvailability/actions';
import { refetchWcsDetails } from 'containers/WorkflowCandidateStepDetails/actions';
import { initialize as newFetchWorkflowById, reFetchAllWorkflowData } from 'containers/NewWorkflowDetails/actions';
import { actionTypeConstants, getActionType } from 'utils/data/actions/actionTypes';
import { refetchUserData } from 'containers/App/actions';
import { refreshWorkflowData } from 'containers/WorkflowList/actions';
import { fetchEvaluatedCandidateDetailViewData } from 'containers/EvaluationDetailView/actions';
import {
  cancelInterviewAction,
  interviewerAcceptCandidateProposedTime,
  interviewerAddSlot,
  interviewerDeclineCandidateProposedTime,
  interviewerDeleteSlot,
  interviewerMeetingDeclineCompleted,
  proposeNewTimeAction,
  scheduleAction,
} from 'utils/mixpanelActions';
import * as types from './constants';
import * as query from './queries';

export const reinviteCandidate = ({ payload, callback }) => ({
  type: types.REINVITE_CANDIDATE,
  payload,
  callback,
  queryString: query.REINVITE_CANDIDATE_QUERY,
});
export const scheduleInterview = ({ payload, callback, additionalContext }) => ({
  type: types.SCHEDULE_INTERVIEW,
  payload,
  callback,
  queryString: query.ASYNC_SCHEDULE_INTERVIEW_QUERY,
  key: 'wft_manage_meeting',
  responseQuery: query.ASYNC_SCHEDULE_INTERVIEW_SUBSCRIPTION,
  refetchActions: [newFetchWorkflowById],
  actionType: getActionType(actionTypeConstants.SCHEDULE_INTERVIEW),
  additionalContext,
  mixPanelAction: scheduleAction,
});
export const publishWorkflow = ({ payload, callback }) => ({
  type: types.PUBLISH_WORKFLOW,
  payload,
  callback,
  queryString: query.PUBLISH_WORKFLOW_QUERY,
});
export const inviteCandidate = ({ payload, callback }) => ({
  type: types.INVITE_CANDIDATE,
  key: 'wft_workflow_invite_candidate_sync',
  payload,
  callback,
  queryString: query.INVITE_CANDIDATE_SYNC_QUERY,
  refetchActions: [refetchWcsDetails],
});

export const bulkInviteCandidates = ({ payload, callback, additionalContext }) => ({
  type: types.BULK_INVITE_CANDIDATES,
  payload,
  callback,
  queryString: query.BULK_INVITE_QUERY,
  key: 'wft_workflow_invite_candidate',
  responseQuery: query.BULK_INVITE_SUBSCRIPTION,
  // refetchActions: [fetchWorkflowById, newFetchWorkflowById],
  actionType: getActionType(actionTypeConstants.BULK_INVITE_CANDIDATE),
  additionalContext,
});

export const remindCandidate = ({ payload, callback }) => ({
  type: types.REMIND_CANDIDATE,
  payload,
  callback,
  queryString: query.REMIND_CANDIDATE_QUERY,
});
export const extendEvaluation = ({ payload, callback }) => ({
  type: types.EXTEND_EVALUATION,
  payload,
  callback,
  queryString: query.EXTEND_EVALUATION_QUERY,
});
export const updateJobDescription = ({ payload, callback }) => ({
  type: types.UPDATE_JOB_DESCRIPTION,
  payload,
  callback,
  queryString: query.UPDATE_JOB_DESCRIPTION_QUERY,
});
export const moveCandidate = ({ payload, callback }) => ({
  type: types.MOVE_CANDIDATE,
  payload,
  callback,
  queryString: query.MOVE_CANDIDATE_QUERY,
});
export const assignPanel = ({ payload, callback }) => ({
  type: types.ASSIGN_PANEL,
  payload,
  callback,
  queryString: query.ASSIGN_EVALUATOR_QUERY,
});
export const remindPanel = ({ payload, callback }) => ({
  type: types.REMIND_PANEL,
  payload,
  callback,
  queryString: query.REMIND_PANEL_QUERY,
});
export const extendLastDate = ({ payload, callback }) => ({
  type: types.EXTEND_LAST_DATE,
  payload,
  callback,
  queryString: query.EXTEND_LAST_DATE_QUERY,
});
export const cancelInterview = ({ payload, callback, additionalContext }) => ({
  type: types.CANCEL_INTERVIEW,
  payload,
  callback,
  queryString: query.CANCEL_INTERVIEW_QUERY,
  key: 'wft_cancel_interview_sync',
  refetchActions: [refetchWcsDetails],
  additionalContext,
  mixPanelAction: cancelInterviewAction,
});
export const confirmInterview = ({ payload, callback }) => ({
  type: types.CONFIRM_INTERVIEW,
  payload,
  callback,
  queryString: query.CONFIRM_INTERVIEW_QUERY,
  refetchActions: [refetchWcsDetails],
});
export const rescheduleInterview = ({ payload, callback }) => ({
  type: types.RESCHEDULE_INTERVIEW,
  payload,
  callback,
  queryString: query.RESCHEDULE_INTERVIEW_QUERY,
  key: 'wft_manage_meeting_sync',
  refetchActions: [refetchWcsDetails],
});

export const assignInterview = ({ payload, callback }) => ({
  type: types.ASSIGN_INTERVIEW,
  payload,
  callback,
  queryString: query.ASSIGN_INTERVIEW_QUERY,
});
export const unpublishWorkflow = ({ payload, callback }) => ({
  type: types.UNPUBLISH_WORKFLOW,
  payload,
  callback,
  queryString: query.UNPUBLISH_WORKFLOW_QUERY,
});
export const updateStatus = ({ payload, callback }) => ({
  type: types.UPDATE_STATUS,
  payload,
  callback,
  queryString: query.UPDATE_STATUS_QUERY,
});

export const createDrive = ({ payload, callback }) => ({
  type: types.CREATE_DRIVE,
  payload,
  callback,
  queryString: query.CREATE_DRIVE,
  key: 'sch_create_drive',
  refetchActions: [reFetchAllWorkflowData],
});

export const onCreateDriveSuccess = ({ payload }) => ({
  type: types.CREATE_DRIVE_SUCCESS,
  payload,
});

export const editDrive = ({ payload, callback }) => ({
  type: types.EDIT_DRIVE,
  payload,
  callback,
  queryString: query.EDIT_DRIVE,
  key: 'sch_update_drive',
});
export const cancelDrive = ({ payload, callback }) => ({
  type: types.CANCEL_DRIVE,
  payload,
  callback,
  queryString: query.CANCEL_DRIVE,
  key: 'sch_cancel_drive',
});

export const cancelOccurrenceDrive = ({ payload, callback }) => ({
  type: types.CANCEL_DRIVE_OCCURRENCE,
  payload,
  callback,
  queryString: query.CANCEL_DRIVE_OCCURRENCE,
  key: 'sch_cancel_drive_occurrence',
});

export const onCancelDriveSuccess = ({ payload }) => ({
  type: types.CANCEL_DRIVE_SUCCESS,
  payload,
});

export const addInterviewerToRoom = ({ payload, callback }) => ({
  type: types.ADD_INTERVIEWER_TO_ROOM,
  payload,
  callback,
  queryString: query.ADD_INTERVIEWER_TO_ROOM,
  key: 'sch_manage_room',
});

export const addRoom = ({ payload, callback }) => ({
  type: types.ADD_ROOM,
  payload,
  callback,
  queryString: query.ADD_ROOM,
  key: 'sch_add_drive_room',
});

export const removeInterviewerFromRoom = ({ payload, callback }) => ({
  type: types.REMOVE_INTERVIEWER_FROM_ROOM,
  payload,
  callback,
  queryString: query.REMOVE_INTERVIEWER,
  key: 'sch_manage_room',
});

export const remindInterviewCandidate = ({ payload, callback }) => ({
  type: types.REMIND_CANDIDATE_INTERVIEW,
  payload,
  callback,
  queryString: '',
});
export const cancelCandidateInterview = ({ payload, callback }) => ({
  type: types.CANCEL_CANDIDATE_INTERVIEW,
  payload,
  callback,
  queryString: '',
});
export const initialize = () => ({
  type: types.INITIALIZE,
});

export const updateScheduleInterviewer = ({ payload, callback }) => ({
  type: types.UPDATE_SCHEDULE_INTERVIEWER,
  payload,
  callback,
  queryString: query.UPDATE_SCHEDULE_INTERVIEWER_QUERY,
  key: 'wft_manage_meeting_sync',
  refetchActions: [refetchWcsDetails],
});

export const editDriveOccurrence = ({ payload, callback }) => ({
  type: types.EDIT_DRIVE_OCCURRENCE,
  payload,
  callback,
  queryString: query.EDIT_DRIVE_OCCURRENCE,
  key: 'sch_update_drive_occurrence',
});

export const removeRoomFromDriveOccurrence = ({ payload, callback }) => ({
  type: types.REMOVE_ROOM_FROM_DRIVE_OCCURRENCE,
  payload,
  callback,
  queryString: query.REMOVE_DRIVE_ROOM,
  key: 'sch_remove_drive_occurrence_room',
});

export const reportIssue = ({ payload, callback }) => ({
  type: types.REPORT_ISSUE,
  payload,
  callback,
  queryString: query.REPORT_ISSUE,
  key: 'admin_create_support_ticket',
});

export const featureRequest = ({ payload, callback }) => ({
  type: types.FEATURE_REQUEST,
  payload,
  callback,
  queryString: query.FEATURE_REQUEST,
  key: 'admin_create_feature_request',
});

export const updateUser = ({ payload, callback }) => ({
  type: types.UPDATE_USER,
  payload,
  callback,
  queryString: query.UPDATE_USER,
  key: 'auth_update_user',
  refetchActions: [refetchUserData],
});

export const uploadFile = ({ payload, callback }) => ({
  type: types.UPLOAD_FILE,
  payload,
  callback,
  queryString: query.UPLOAD_FILE,
  key: 'auth_update_user',
});

export const updateCandidateStep = ({ payload, callback }) => ({
  type: types.UPDATE_CANDIDATE_STEP,
  payload,
  callback,
  queryString: query.UPDATE_CANDIDATE_STEP,
  key: 'wft_update_workflow_candidate_step_sync',
  refetchActions: [refetchWcsDetails],
});

export const exportData = ({ payload, callback, additionalContext }) => ({
  type: types.EXPORT_DATA,
  payload,
  callback,
  additionalContext,
});

export const changePassword = ({ payload, callback }) => ({
  type: types.UPDATE_PASSWORD,
  payload,
  callback,
  queryString: query.UPDATE_PASSWORD,
  key: 'auth_update_user_password',
});

export const updateCollaborator = ({ payload, callback }) => ({
  type: types.UPDATE_COLLABORATOR,
  payload,
  callback,
  queryString: query.UPDATE_COLLABORATOR,
  key: 'wft_workflow_upsert_workflow_collaborator_sync',
  refetchActions: [refreshWorkflowData],
});

export const fetchFileUrl = ({ queryVariables, callback }) => ({
  type: types.FETCH_FILE_URL,
  queryVariables,
  callback,
});

export const createWorkflow = ({ payload, callback }) => ({
  type: types.CREATE_WORKFLOW,
  payload,
  callback,
  queryString: query.CREATE_WORKFLOW,
  key: 'wft_create_workflow',
  refetchActions: [refreshWorkflowData],
});

export const declineInterview = ({ payload, callback }) => ({
  type: types.DECLINE_INTERVIEW,
  payload,
  callback,
  queryString: query.DECLINE_INTERVIEW,
  key: 'sch_rsvp_action',
  mixPanelAction: interviewerMeetingDeclineCompleted,
});

export const proposeNewTime = ({ payload, callback, additionalContext }) => ({
  type: types.PROPOSE_NEW_TIME,
  payload,
  callback,
  queryString: query.PROPOSE_NEW_TIME,
  key: 'sch_rsvp_action',
  mixPanelAction: proposeNewTimeAction,
  additionalContext,
});
export const rescheduleMeeting = ({ payload, callback, additionalContext }) => ({
  type: types.RESCHEDULE_MEETING,
  payload,
  callback,
  queryString: query.RESCHEDULE_MEETING,
  key: 'sch_reschedule_meeting',
  additionalContext,
});

export const assignEvaluator = ({ payload, callback }) => ({
  type: types.ASSIGN_EVALUATOR,
  payload,
  callback,
  queryString: query.ASSIGN_EVALUATOR,
  key: 'wft_workflow_assign_evaluator_sync',
});

export const acceptProposedNewTime = ({ payload, callback, additionalContext }) => ({
  type: types.ACCEPT_PROPOSED_NEW_TIME,
  payload,
  callback,
  queryString: query.ACCEPT_PROPOSED_NEW_TIME,
  key: 'sch_reschedule_meeting',
  mixPanelAction: interviewerAcceptCandidateProposedTime,
  additionalContext,
});

export const declineProposedNewTime = ({ payload, callback, additionalContext }) => ({
  type: types.DECLINE_PROPOSED_NEW_TIME,
  payload,
  callback,
  queryString: query.DECLINE_PROPOSED_NEW_TIME,
  key: 'sch_decline_proposed_time',
  mixPanelAction: interviewerDeclineCandidateProposedTime,
  additionalContext,
});

export const deleteAvailability = ({ payload, callback, additionalContext }) => ({
  type: types.DELETE_AVAILABILITY,
  payload,
  callback,
  queryString: query.DELETE_AVAILABILITY,
  key: 'sch_delete_availability',
  refetchActions: additionalContext?.skipReFetch ? [] : [fetchAvailabilities],
  mixPanelAction: interviewerDeleteSlot,
  additionalContext,
});

export const addAvailability = ({ payload, callback }) => ({
  type: types.ADD_AVAILABILITY,
  payload,
  callback,
  queryString: query.ADD_AVAILABILITY,
  key: 'sch_set_availability',
  refetchActions: [fetchAvailabilities],
  mixPanelAction: interviewerAddSlot,
});

export const updateMeetingParticipationStatus = ({ payload, callback }) => ({
  type: types.UPDATE_MEETING_PARTICIPATION_STATUS,
  payload,
  callback,
  queryString: query.UPDATE_MEETING_PARTICIPATION_STATUS,
  key: 'sch_update_meeting_participant_status',
});

export const completeMeeting = ({ payload, callback }) => ({
  type: types.COMPLETE_MEETING,
  payload,
  callback,
  queryString: query.COMPLETE_MEETING,
  key: 'sch_meeting_complete',
});
export const addSchOverride = ({ payload, callback }) => ({
  type: types.ADD_SCH_OVERRIDE,
  payload,
  callback,
  queryString: query.ADD_SCH_OVERRIDE,
  key: 'sch_override_slots',
  refetchActions: [fetchAvailabilities],
});

export const updateDriveMeetingParticipationStatus = ({ payload, callback }) => ({
  type: types.UPDATE_DRIVE_MEETING_PARTICIPATION_STATUS,
  payload,
  callback,
  queryString: query.UPDATE_DRIVE_MEETING_PARTICIPATION_STATUS,
  key: 'sch_update_meeting_participant_status',
});

export const updateDriveMemberStatus = ({ payload, callback }) => ({
  type: types.UPDATE_DRIVE_MEMBER_STATUS,
  payload,
  callback,
  queryString: query.UPDATE_DRIVE_MEMBER_STATUS,
  key: 'sch_update_drive_member_status',
});

export const deleteScheduleOverride = ({ payload, callback }) => ({
  type: types.DELETE_SCHEDULE_OVERRIDE,
  payload,
  callback,
  queryString: query.DELETE_SCHEDULE_OVERRIDE,
  key: 'sch_delete_override',
  refetchActions: [fetchAvailabilities],
});

export const generateVoiceToken = ({ payload, callback }) => ({
  type: types.GENERATE_VOICE_TOKEN,
  payload,
  callback,
  queryString: query.FETCH_VOICE_TOKEN_TWILIO,
  key: 'voip_token',
});

export const confirmSlotBooking = ({ payload, callback }) => ({
  type: types.CONFIRM_SLOT_BOOKING,
  payload,
  callback,
  queryString: query.CONFIRM_MEETING_SLOT,
  key: 'wft_confirm_meeting_slot',
  spreadPayload: true,
});

export const submitEvaluation = ({ payload, callback }) => ({
  type: types.SUBMIT_EVALUATION,
  payload,
  callback,
  queryString: query.SUBMIT_EVALUATION,
  key: 'tcf_submit_evaluation',
  spreadPayload: true,
  refetchActions: [fetchEvaluatedCandidateDetailViewData, refetchWcsDetails],
});

export const respondNotification = ({ payload, callback }) => ({
  type: types.RESPOND_NOTIFICATION,
  payload,
  callback,
  queryString: query.RESPOND_NOTIFICATION_ACTION,
  key: 'notify_message_action',
  spreadPayload: true,
});

import classNames from 'classnames';
import React from 'react';
import Tooltip from 'components/base/Tooltip';

const AvatarTooltip = (props) => {
  const { fullName, email, id, anchor } = props;

  return (
    <Tooltip id={id} anchor={anchor} placement="bottom-start" offsetValue={10}>
      <div className={classNames('px-2 py-1 bg-gray-900 text-white text-[10px] rounded')}>
        <div>{fullName}</div>
        <div>{email}</div>
      </div>
    </Tooltip>
  );
};

export default AvatarTooltip;

export const REINVITE_CANDIDATE_QUERY = `
mutation reinviteCandidate($payload:[wft_workflow_reinvite_input!]){
  wft_workflow_reinvite(data: $payload)
}
`;
export const SCHEDULE_INTERVIEW_QUERY = `
mutation scheduleInterview($payload:[wft_workflow_manage_meeting_input]!){
  wft_manage_meeting_sync(data: $payload){
    data
    success
    error_message
  }
}
`;

export const BULK_INVITE_QUERY = `
  mutation bulkInviteCandidate($payload: [wft_workflow_invite_input!]) {
    wft_workflow_invite_candidate(data: $payload)
  }
`;

export const BULK_INVITE_SUBSCRIPTION = `
  subscription InviteCandidateSubscription($id: uuid!) {
    wft_workflow_invite_candidate(id: $id) {
      output {
        data {
          payload
          key
          error_message
          success
        }
        error_count
        success_count
      }
      errors
      id
    }
  }
`;

export const ASYNC_SCHEDULE_INTERVIEW_QUERY = `
  mutation scheduleInterview($payload: [wft_workflow_manage_meeting_input]!) {
    wft_manage_meeting(data: $payload)
  }
`;

export const ASYNC_SCHEDULE_INTERVIEW_SUBSCRIPTION = `
  subscription scheduleInterviewOutput($id: uuid!) {
    wft_manage_meeting(id: $id) {
      id
      output {
        data
        error_message
        success
      }
      errors
    }
  }
`;

export const PUBLISH_WORKFLOW_QUERY = `
mutation publishWorkflow($payload:[workflowPublishWorkflowInput!]){
  workflowPublishWorkflowCandidate(data: $payload)
}
`;
export const INVITE_CANDIDATE_QUERY = `
mutation inviteCandidate($payload:[wft_workflow_invite_input!]){
  wft_workflow_invite_candidate(data: $payload)
}
`;

export const INVITE_CANDIDATE_SYNC_QUERY = `
  mutation InviteCandidateSync($payload:[wft_workflow_invite_input!] ) {
    wft_workflow_invite_candidate_sync(
      data: $payload
    ) {
      data {
        error_message
        payload
        success
      }
      success_count
      error_count
    }
  }
`;

export const REMIND_CANDIDATE_QUERY = `
mutation remindCandidate($payload: [wft_workflow_remind_input!]) {
  wft_workflow_remind(data: $payload)
}
`;
export const EXTEND_EVALUATION_QUERY = `
mutation extendEvaluation($payload:[workflowExtendEvaluationInput!]){
  workflowExtendEvaluationCandidate(data: $payload)
}
`;
export const UPDATE_JOB_DESCRIPTION_QUERY = `
mutation updateJobDescription($payload:[workflowUpdateJobDescriptionInput!]){
  workflowUpdateJobDescriptionCandidate(data: $payload)
}
`;
export const MOVE_CANDIDATE_QUERY = `
mutation moveCandidate($payload:[workflowMoveCandidateInput!]){
  workflowMoveCandidateCandidate(data: $payload)
}
`;
export const ASSIGN_EVALUATOR_QUERY = `
mutation assignEvaluator($payload: [wft_workflow_assign_evaluator_input!]) {
  wft_workflow_assign_evaluator(data: $payload)
}
`;
export const REMIND_PANEL_QUERY = `
mutation remindPanel($payload:[workflowRemindPanelInput!]){
  workflowRemindPanelCandidate(data: $payload)
}
`;
export const EXTEND_LAST_DATE_QUERY = `
mutation extendLastDate($payload:[wft_extend_end_date_input!]){
  wft_extend_end_date(data: $payload)
}
`;
export const CANCEL_INTERVIEW_QUERY = `
mutation cancelInterview($payload:[wft_workflow_cancel_interview_input!]){
  wft_cancel_interview_sync(data: $payload) {
    error_count
    success_count
  }
}
`;
export const RESCHEDULE_INTERVIEW_QUERY = `
mutation rescheduleInterview($payload:[wft_workflow_manage_meeting_input]!){
  wft_manage_meeting_sync(data: $payload) {
    data
    success
    error_message
  }
}
`;
export const ASSIGN_INTERVIEW_QUERY = `
mutation assignInterview($payload:[workflowAssignInterviewInput!]){
  workflowAssignInterviewCandidate(data: $payload)
}
`;
export const UNPUBLISH_WORKFLOW_QUERY = `
mutation unpublishWorkflow($payload:[workflowUnpublishWorkflowInput!]){
  workflowUnpublishWorkflowCandidate(data: $payload)
}
`;
export const UPDATE_STATUS_QUERY = `
mutation updateStatus($payload:[wft_workflow_update_status_input!]){
  wft_workflow_update_status(data: $payload)
}
`;
export const GET_WFT_STATUS = `
query status($conditions: [wft_status_bool_exp!], $searchString: String) {
  wft_status(
    where: {
      _and: $conditions,
      _or: {
        name: {
          _ilike: $searchString
        }
      }
    }
  ) {
    name
    id
    type
    status_reasons{
      id,
      key
      caption
    }
  }
}
`;

export const GET_AUTH_USER = `
  query getAuthUser($conditions: [auth_user_bool_exp!], $searchString: String) {
    auth_user(
      where: {
        _and: $conditions
        _or: [{external_id: {_ilike: $searchString}},{name: {_ilike: $searchString}}]
      }
      order_by: {id: desc}
    ) {
      id
      name
      username,
      external_id
      email
    }
  }
`;
export const GET_WORKFLOWS = `
  query titles($searchString: String, $conditions: [wft_workflow_bool_exp!]) {
    wft_workflow(
      where: {
        _and: $conditions
        _or: [{ title: { _ilike: $searchString } }, { job: { external_id: { _ilike: $searchString } } }]
      }
      limit: 10
      offset: 0
      order_by: { id: desc }
    ) {
      id
      title,
      steps {
        id
        name
        step_type
      }
      job {
        external_id
      }
    }
  }`;
export const GET_TIMEZONES = `
  query getTimezones($searchString: String) {
    md_timezone(
      order_by: {value: asc}
      where: {_or: {description: {_ilike: $searchString}, value: {_like: $searchString}}}
    ) {
      description
      value
    }
  }
  `;
export const CREATE_DRIVE = `
  mutation create_drive($payload:[sch_create_drive_input]!){
    sch_create_drive(data: $payload) {
      success
      error_message
    }
  }
`;

export const EDIT_DRIVE = `
  mutation editDrive($payload: [sch_update_drive_input]!){
    sch_update_drive(data: $payload) {
      success
      error_message
      data
    }
  }
`;

export const CANCEL_DRIVE = `
  mutation cancelDrive($payload:[Int]!){
    sch_cancel_drive(data: {drive_schedule_id: $payload}) {
      success_count
      error_count
    }
  }
`;

export const CANCEL_DRIVE_OCCURRENCE = `
mutation cancelDriveOccurrence($payload: [sch_cancel_drive_occurrence_input]!) {
  sch_cancel_drive_occurrence(data: $payload) {
    error_message
    success
    data
  }
}`;

export const REMOVE_INTERVIEWER = `
  mutation removeInterviewerFromRoom($payload:[sch_manage_room_input]!){
    sch_manage_room(data: $payload){
      error_message
      success
    }
  }
`;

export const ADD_INTERVIEWER_TO_ROOM = `
  mutation addInterviewerToRoom($payload:[sch_manage_room_input]!){
    sch_manage_room(data: $payload) {
      error_message
      success
    }
  }
`;

export const ADD_ROOM = `
  mutation addRoom($payload:[sch_add_drive_room_input]!){
    sch_add_drive_room(data: $payload){
      error_count
      success_count
    }
  }
`;

export const SUBSCRIPTION_CREATE_DRIVE = `
  subscription createDrive($id: uuid!) {
    workflowCreateDrive(id: $id) {
      errors
      id
      output {
        accessToken
      }
      created_at
    }
  }
`;

export const UPDATE_SCHEDULE_INTERVIEWER_QUERY = `
  mutation updateScheduleInterviewer($payload:[wft_workflow_manage_meeting_input]!){
    wft_manage_meeting_sync(data: $payload){
      data
      success
      error_message
    }
  }
`;

export const EDIT_DRIVE_OCCURRENCE = `
  mutation editDriveOccurrence($payload: [sch_update_drive_occurrence_input]!) {
    sch_update_drive_occurrence (data: $payload) {
      data
      success
      error_message
    }
  }
`;

export const REMOVE_DRIVE_ROOM = `
  mutation removeDriveRoom($payload: [sch_remove_drive_occurrence_room_input]!) {
    sch_remove_drive_occurrence_room(data: $payload) {
      data
      error_message
      success
    }
  }
`;

export const FEATURE_REQUEST = `
  mutation createFeatureRequest($payload: [admin_create_feature_request_input!]!){
    admin_create_feature_request(data: $payload) {
      error_message
      success
    }
}`;

export const REPORT_ISSUE = `
mutation reportIssue($payload: [admin_create_support_ticket_input]!) {
  admin_create_support_ticket(data: $payload) {
    success
    error_message
  }
}
`;

export const UPDATE_USER = `
mutation updateUser($payload: auth_update_user_input!){
  auth_update_user(data: $payload){
    error_message
    success
  }
}
`;

export const UPDATE_CANDIDATE_STEP = `
mutation updateCandidate($payload:[wft_update_workflow_candidate_step_input!]){
  wft_update_workflow_candidate_step_sync(data: $payload){
    data{
      id
    },
    error_message,
    success
  }
}`;

export const UPDATE_PASSWORD = `
mutation changePassword($payload: auth_update_user_password_input!) {
  auth_update_user_password (data: $payload) {
    error_message
    success
  }
}`;

export const UPLOAD_FILE = `
mutation uploadFile($file_type_id: Int!, $original_name: String!, $owner_id: Int!, $extension:String!){
  file_upload_file( file_type_id : $file_type_id , original_name:$original_name ,
  owner_id:  $owner_id,extension: $extension ){
    id
    data
    expires_in
    key
  }
}
`;

export const UPDATE_COLLABORATOR = `
mutation updateCollaborator($payload: [wft_workflow_upsert_workflow_collaborator_input!]) {
  wft_workflow_upsert_workflow_collaborator_sync(data: $payload) {
    success
    error_message
  }
}
`;

export const FETCH_FILE_URL = `
query file_download_file($id: Int!) {
  file_download_file(id: $id) {
    resource_url
    id
  }
}
`;

export const CREATE_WORKFLOW = `
mutation createWorkflow($payload: [wft_create_workflow_input!]!) {
  wft_create_workflow(data: $payload) {
    data
    success
  }
}
`;

export const DECLINE_INTERVIEW = `
  mutation declineInterview($payload: [sch_rsvp_action_input!]!) {
    sch_rsvp_action(data: $payload) {
      error_count
      success_count
    }
  }
`;

export const CONFIRM_INTERVIEW_QUERY = `
  mutation confirmInterview($payload: [sch_rsvp_action_input!]!) {
    sch_rsvp_action(data: $payload) {
      error_count
      success_count
    }
  }
`;

export const PROPOSE_NEW_TIME = `
  mutation proposeNewTime($payload: [sch_rsvp_action_input!]!) {
    sch_rsvp_action(data: $payload) {
    error_count
    success_count
  }
}
`;

export const ASSIGN_EVALUATOR = `
mutation assignEvaluator($payload: [wft_workflow_assign_evaluator_input!]) {
  wft_workflow_assign_evaluator_sync(data: $payload){
  success
  error_message
  }
}`;

export const ACCEPT_PROPOSED_NEW_TIME = `
mutation acceptProposedNewTime($payload:[sch_reschedule_meeting_input!]!){
  sch_reschedule_meeting(data: $payload) {
    data {
      to
      from
    }
  }
}
`;

export const RESCHEDULE_MEETING =
  // eslint-disable-next-line max-len
  `mutation rescheduleMeeting( $payload:[sch_reschedule_meeting_input!]!) {
  sch_reschedule_meeting( data: $payload) {
    data {
      to
      name
    }
  }
}
`;

export const DECLINE_PROPOSED_NEW_TIME = `
mutation declineProposedTime($payload:[sch_decline_proposed_time_input!]!) {
  sch_decline_proposed_time(data: $payload) {
    data {
      meeting_participant_id
      proposed_new_time
    }
    error_count
    success_count
  }
}
`;

export const DELETE_AVAILABILITY = `
  mutation deleteAvailability($payload: [sch_delete_availability_input]!) {
    sch_delete_availability(data: $payload) {
      success_count
      error_count
    }
}
`;

export const ADD_AVAILABILITY = `
  mutation addAvailability($payload: sch_set_availability_input!) {
    sch_set_availability(data: $payload) {
      success_count
      error_count
    }
  }
`;

export const UPDATE_MEETING_PARTICIPATION_STATUS = `
  mutation updateMeetingParticipationStatus($payload: [sch_update_meeting_participant_status_input!]) {
    sch_update_meeting_participant_status(data: $payload){
      data
      success
     error_message
    }
  }
`;

export const COMPLETE_MEETING = `
  mutation completeMeeting($payload:[sch_meeting_complete_input]!) {
    sch_meeting_complete(data: $payload) {
      success
      error_message
      data {
        meeting_id
      }
    }
  }
`;
export const ADD_SCH_OVERRIDE = `
  mutation overrideSlots($payload: sch_override_slots_input!) {
    sch_override_slots(data: $payload) {
      error_count
      message
      overrides {
        from_date
        is_holiday
        reason
        to_date
      }
      success_count
    }
  }
`;
export const UPDATE_DRIVE_MEETING_PARTICIPATION_STATUS = `
  mutation updateDriveMeetingParticipantStatus($payload: [sch_update_meeting_participant_status_input]!) {
    sch_update_meeting_participant_status(data:$payload) {
      data
      error_message
      success
    }
  }
`;

export const UPDATE_DRIVE_MEMBER_STATUS = `
  mutation updateDriveMemberStatus($payload: [sch_update_drive_member_status_input!]) {
    sch_update_drive_member_status(data: $payload) {
      error_message
      success
    }
  }
`;

export const DELETE_SCHEDULE_OVERRIDE = `
  mutation deleteScheduleOverride($payload: [sch_override_delete_data!]!) {
    sch_delete_override(data: $payload) {
      error_count
      success_count
    }
  }
`;

export const FETCH_VOICE_TOKEN_TWILIO = `
  mutation spt_voip_token {
    voip_token {
      error_message
      success
      data {
        access_token
        identity
      }
    }
  }
`;

export const CONFIRM_MEETING_SLOT = `
  mutation confirmMeetingSlot($meeting_id: Int!, $slot_time: timestamptz!,$timezone:String! ) {
    wft_confirm_meeting_slot(meeting_id: $meeting_id, slot_time: $slot_time, timezone: $timezone) {
      meeting_id
      message
      slot_time
    }
  }
`;

export const SUBMIT_EVALUATION = `
  mutation submitEvaluation(
    $measurement_attributes: [tcf_measurement_attribute_input!]!
    $section_configuration_id: Int!
    $workflow_candidate_step_id: Int
  ) {
    tcf_submit_evaluation(
      measurement_attributes: $measurement_attributes
      section_configuration_id: $section_configuration_id
      workflow_candidate_step_id: $workflow_candidate_step_id
    ) {
      success
      error_message
    }
  }
`;

export const RESPOND_NOTIFICATION_ACTION = `
  mutation notificationAction(
    $action: notify_message_action_enum!
    $model_id: Int!
    $content: jsonb
    $to_address: String
  ) {
    notify_message_action(
      action: $action
      model_id: $model_id
      content: $content
      to_address: $to_address
    ) {
      model_id
      thread_id
      uuid
    }
  }
`;

import localforage from 'localforage';
import {
  capitalize,
  compact,
  filter,
  find,
  findIndex,
  flatMap,
  flatten,
  forEach,
  get,
  has,
  identity,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isNil,
  isObject,
  lowerCase,
  map,
  omitBy,
  pickBy,
  range,
  reduce,
  replace,
  sortBy,
  split,
  toNumber,
  trim,
  uniq,
  uniqBy,
} from 'lodash';
import queryStr from 'query-string';
import { DateTime, Duration } from 'luxon';
import * as Sentry from '@sentry/react';
import { triggerToast } from 'components/base/Notification';
import { getAvailableRoomSlots } from 'utils/driveHelpers';
import {
  getCurrentRole,
  meetingParticipantRole,
  ROLE_ADMIN,
  ROLE_COLLABORATOR,
  ROLE_CONTENT_MANAGER,
  ROLE_EVALUATOR,
  ROLE_HIRING_MANAGER,
  ROLE_MASTER_CONTENT_MANAGER,
  ROLE_MASTER_RECRUITER,
  ROLE_RECRUITER,
  ROLE_TENANT_ADMIN,
} from './data/roles';
import { getRecurrenceDetails, shouldDisableDriveActionsAsPerOccurrence } from './data/recurrence';
import { getFormattedRecommendationStatus } from './data/recommendationStatus';
import getDisplayName from './getDisplayName';
import { driveGuestStatus, getFormattedDriveGuestStatus } from './data/driveGuestStatus';
import durationList from './data/durations';
import { driveStatus, getFormattedDriveStatus } from './data/driveStatus';

export function isNumeric(str) {
  if (typeof str !== 'string') return false; // we only process strings!
  // eslint-disable-next-line no-restricted-globals
  return !Number.isNaN(str) && !isNaN(str);
}

export function formatBytes(bytes, decimals = 0) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function formattedTime(value) {
  try {
    return value ? DateTime.fromSeconds(value).toISO().substr(11, 8) : null;
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
}

export async function getDataByIds(value, getByIds) {
  const v = !isArray(value) ? [trim(value)] : value;
  const ids = map(v, (i) => toNumber(i));

  if (ids.length === 0 || isEmpty(value)) {
    return Promise.resolve([]);
  }

  return getByIds({ ids });
}

// export const makeArray = attr => {
//   let res;
//   if (attr) {
//     res = !isArray(attr) ? `[${split(attr, ',').toString()}]` : `[${attr.toString()}]`;
//   }
//   return res;
// };

export const getParams = (filters, f) =>
  reduce(
    f,
    (o, value, key) =>
      find(filters, { key })
        ? { ...o, ...{ [key]: get(find(filters, { key }), 'isDate', false) ? split(value, ',') : value } }
        : o,
    {},
  );

export const parseJwt = (token) => {
  if (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );
    return JSON.parse(jsonPayload);
  }
  return {};
};

// export const historyUpdate = ({ filterKeys, searchString, currentPage, base, history, filters, loading }) => {
//   let fc;
//   if (filterKeys) {
//     fc = reduce(
//       filterKeys,
//       (a, i) => (has(filters, i) && !isNil(filters[i]) ? `${a ? `${a}&` : ''}${i}=${filters[i]}` : a),
//       '',
//     );
//   } else {
//     fc = reduce(Object.keys(filters), (a, i) => (!isNil(filters[i]) ? `${a}&${i}=${filters[i]}` : a), '');
//   }

//   const qc = searchString ? `search=${encodeURIComponent(searchString)}` : '';
//   const pc = currentPage >= 1 ? `&page=${currentPage}` : '';
//   const newRoute = `/${base}?${qc}${fc}${pc}`;

//   if (!loading && `?${qc}${fc}${pc}` !== history.location.search) {
//     history.push(newRoute);
//   }
// };

export function pageNumbers({ pages, current }) {
  let max = pages;
  if (pages > current + 2 && current <= 3) {
    max = 5;
  } else if (current + 2 < pages) {
    max = current + 2;
  }
  let min = 1;
  if (pages < current + 3 && current > 3) {
    min = pages - 4;
  } else if (current > 3) {
    min = current - 2;
  }
  return range(min, max + 1);
}

export const getQueryString = (filteredParams) =>
  reduce(
    Object.keys(filteredParams),
    (a, i) =>
      has(filteredParams, i) && !isNil(filteredParams[i]) && !isObject(filteredParams[i]) && !isEmpty(filteredParams[i])
        ? `${a ? `${a}&` : ''}${i}=${encodeURIComponent(filteredParams[i])}`
        : a,
    '',
  );

export const parseJson = (items) =>
  compact(
    map(items, (item) => {
      try {
        return JSON.parse(item);
      } catch (e) {
        Sentry.captureException(e);
        return null;
      }
    }),
  );

export const parseConditions = ({ fields, variables }) => {
  const items = flatten(
    map(
      filter(fields, (f) => f.query),
      'query',
    ),
  );

  return parseJson(
    map(pickBy(variables, identity), (value, key) =>
      replace(get(find(items, { key: `$${key}` }), 'conditions'), new RegExp(`[$]${key}`, 'g'), value),
    ),
  );
};

const parseQuery = ({ key, query, filters, currentFilter }) => {
  const queryString = query.map((q, i) => {
    let conditions = JSON.stringify({});
    const values = filters[key];
    if (values && Array.isArray(values) && values.length) {
      if (query.length > 1) {
        if (values.length === query.length) {
          const value = values[i];
          if (value && currentFilter?.primaryKey && typeof value === 'object') {
            conditions = q.conditions.replace(q.key, value[currentFilter.primaryKey]);
          }
          conditions = q.conditions.replace(q.key, value);
        }
      } else {
        conditions = q.conditions.replaceAll(
          q.key,
          JSON.stringify(
            values.map((v) => {
              if (v && currentFilter?.primaryKey && typeof v === 'object') {
                return v[currentFilter.primaryKey];
              }
              return v;
            }),
          ),
        );
      }
    } else if (isBoolean(values)) {
      conditions = q.conditions.replace(q.key, values);
    }
    return JSON.parse(conditions.toString());
  });
  return filter(queryString, (qs) => Object.keys(qs).length);
};

export function constructQueryFilter(filters, filterLists) {
  if (filters && Object.keys(filters).length && filterLists && Array.isArray(filterLists) && filterLists.length) {
    return flatten(
      Object.keys(filters).map((key) => {
        const currentFilter = filterLists.find((fl) => fl.key === key);
        const query = currentFilter?.query;
        if (query && Array.isArray(query) && query.length) {
          return parseQuery({ key, query, filters, currentFilter });
        }
        return [];
      }),
    );
  }
  return (filters && Object.keys(filters).length) || [];
}

export const updateHistory = (
  { filter: af, pageIndex: p, pageSize: ps, searchText: s, ...query },
  navigate,
  location,
) => {
  const q = omitBy(query, isNil);
  const f = af && Object.keys(af).length ? JSON.stringify(af) : null;
  const stringified = queryStr.stringify(
    {
      ...q,
      ps,
      p,
      s,
      filter: f,
    },
    {
      skipNull: true,
      skipEmptyString: true,
    },
  );
  navigate(location.pathname, {
    search: `?${stringified}`,
    replace: true,
  });
};

// export const getFormattedSearchParams = ({ filter: af, pageIndex: p, pageSize: ps, searchText: s, ...query }) => {
//   const q = omitBy(query, isNil);
//   const f = af && Object.keys(af).length ? JSON.stringify(af) : null;
//   return omitBy(
//     {
//       ...q,
//       ps,
//       p,
//       s,
//       filter: f,
//     },
//     isNil,
//   );
// };

export const parseCandidates = (data) => {
  if (data && Array.isArray(data) && data.length) {
    return map(data, (d) => {
      if (get(d, 'candidate')) {
        return get(d, 'candidate');
      }
      return get(d, 'workflowCandidate.candidate');
    });
  }
  return [];
};

export const parseEvaluations = (data) => {
  if (data && Array.isArray(data) && data.length) {
    let wcs = data;
    if (get(data[0], 'workflow_candidate_steps')) {
      wcs = flatMap(map(data, (wc) => get(wc, 'workflow_candidate_steps', [])));
    }
    const wse = flatMap(map(wcs, (d) => get(d, 'workflow_step_evaluations', [])));
    return wse;
  }
  return [];
};

export const getConflict = (slotA, slotB) => {
  if (slotA?.id === slotB?.id) {
    return false;
  }
  const caseStartAt = +new Date(slotB.startDate);
  const caseEndAt = +new Date(slotB.endDate);
  const dataStartAt = +new Date(slotA.startDate);
  const dataEndAt = +new Date(slotA.endDate);
  const hasStartTimeConflict = caseStartAt > dataStartAt && caseStartAt < dataEndAt;
  const hasEndTimeConflict = caseEndAt > dataStartAt && caseEndAt < dataEndAt;
  const isSameStartTime = caseStartAt && dataStartAt ? caseStartAt === dataStartAt : false;
  const isSameEndTime = caseEndAt && dataEndAt ? caseEndAt === dataEndAt : false;
  return hasStartTimeConflict || hasEndTimeConflict || isSameStartTime || isSameEndTime;
};

export const dateTimeSortType = (rowA, rowB, columnId) => {
  const getRowValuesByColumn2 = [rowA.values[columnId], rowB.values[columnId]];
  let a = getRowValuesByColumn2[0];
  let b = getRowValuesByColumn2[1];

  a = a ? new Date(a).getTime() : 0;
  b = b ? new Date(b).getTime() : 0;
  // eslint-disable-next-line no-nested-ternary
  return a === b ? 0 : a > b ? 1 : -1;
};

export const newDateTimeSortType = (rowA, rowB, columnID, sortDesc) => {
  if (rowA?.original?.from && rowB?.original?.from) {
    if (rowA?.original?.date === rowB?.original?.date) {
      let a = rowA?.original?.from;
      let b = rowB?.original?.from;
      a = a ? new Date(a).getTime() : 0;
      b = b ? new Date(b).getTime() : 0;
      // eslint-disable-next-line no-nested-ternary
      return a === b ? 0 : a > b ? 1 : -1;
    }
  }

  if (sortDesc) return -1;
  return 0;
};
export function scoreSortType(rowA, rowB, columnId) {
  const valueA = rowA.values[columnId]
    ? find(rowA.values[columnId]?.workflow_step_scores || [], ['skill_id', null])
    : null;
  const valueB = rowB.values[columnId]
    ? find(rowB.values[columnId]?.workflow_step_scores || [], ['skill_id', null])
    : null;
  let a = valueA?.score;
  let b = valueB?.score;
  if (!a) {
    a = 0;
  }
  if (!b) {
    b = 0;
  }
  const replaceNonNumeric = /[^0-9.]/gi;
  a = Number(String(a).replace(replaceNonNumeric, ''));
  b = Number(String(b).replace(replaceNonNumeric, ''));
  // eslint-disable-next-line no-nested-ternary
  return a === b ? 0 : a > b ? 1 : -1;
}

export const dateCompare = (dateRange, value) => {
  const min = +new Date(dateRange[0]);
  const max = +new Date(dateRange[1]);
  return value && new Date(value) ? +new Date(value) >= min && +new Date(value) <= max : false;
};

export const getByKey = (data) => {
  const byId = {};
  if (data && Object.keys(data).length) {
    Object.keys(data).forEach((key) => {
      byId[key] = Array.isArray(data[key]) ? data[key][0] : data[key];
    });
  }
  return byId;
};

export const catchError = (error, skipToast) => {
  if (process.env.NODE_ENV !== 'production' && console) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
  if (!skipToast) {
    triggerToast({
      variant: 'danger',
      setting: { position: 'top-right' },
      message: {
        title: 'Oh! Something went wrong',
        summary: error.message,
      },
    });
  }
  Sentry.captureException(error);
};

export const getTimeIntervals = (startTime, endTime, timeInterval) => {
  const startAt =
    startTime && DateTime.fromISO(startTime).isValid
      ? DateTime.fromISO(startTime)
      : DateTime.now().startOf('day').plus({ hours: 9 });
  const endAt =
    endTime && DateTime.fromISO(endTime).isValid
      ? DateTime.fromISO(endTime)
      : DateTime.now().startOf('day').plus({ hours: 22 });
  let duration;
  if (endAt > startAt) {
    duration = endAt.diff(startAt, 'minutes');
  } else {
    duration = startAt.diff(endAt, 'minutes');
  }
  const length = duration.as('minutes') / timeInterval;
  const list = [];
  forEach(new Array(Math.ceil(length)), (e, i) => {
    const st = startAt.plus({ minutes: i * timeInterval });
    list.push({
      content: st.toFormat('hh:mm a'),
      id: st.toISO(),
      date: st,
    });
  });
  return list;
};

export const getEvaluationStatus = (evaluations) => {
  if (evaluations && Array.isArray(evaluations) && evaluations.length) {
    const latestEvaluation = getFormattedRecommendationStatus(evaluations[0]);
    return {
      name: latestEvaluation?.label,
      ...latestEvaluation,
    };
  }
  return null;
};

export function formatRoles(role) {
  switch (role) {
    case ROLE_ADMIN:
      return 'Admin';
    case ROLE_RECRUITER:
      return 'Recruiter';
    case ROLE_MASTER_RECRUITER:
      return 'Master Recruiter';
    case ROLE_EVALUATOR:
      return 'Evaluator';
    case ROLE_COLLABORATOR:
      return 'Collaborator';
    case ROLE_CONTENT_MANAGER:
      return 'Content Manager';
    case ROLE_TENANT_ADMIN:
      return 'Tenant Admin';
    case ROLE_HIRING_MANAGER:
      return 'Hiring Manager';
    case ROLE_MASTER_CONTENT_MANAGER:
      return 'Master Content Manager';
    default:
      return role;
  }
}

export function getFormattedRoles(roles) {
  if (roles && Array.isArray(roles)) {
    if (roles.length) {
      const data = map(roles, (d) => {
        const role = d && d?.role ? d.role : d;
        return formatRoles(role);
      });
      return data;
    }
    return [];
  }
  if (roles && Object.keys(roles).length) {
    return formatRoles(roles?.role);
  }
  return formatRoles(roles);
}

export const dayDifference = (timestamp) => {
  const today = getCurrentDate().startOf('day');
  const parseDate = DateTime.fromISO(timestamp);
  if (timestamp && parseDate && parseDate.isValid) {
    return today.diff(parseDate, 'days');
  }
  return null;
};

export const getFormattedDriveRooms = (rooms, interviewSteps) => {
  if (rooms && Array.isArray(rooms) && rooms.length) {
    const formattedRooms = map(rooms, (room) => {
      if (room?.id && room?.step_id && interviewSteps && Array.isArray(interviewSteps) && interviewSteps.length) {
        const interviewStep = find(interviewSteps, ['id', room?.step_id]);
        return {
          ...room,
          step: interviewStep,
        };
      }
      return room;
    });
    return sortBy(formattedRooms, [(room) => get(room, 'step.sequence'), (room) => get(room, 'id')]);
  }
  return [];
};

export const getDriveDefaultDate = (drive) => {
  const currentDate = process.env.NODE_ENV === 'test' ? DateTime.fromISO('2022-11-19T08:30:23+05:30') : DateTime.now();
  const startDate =
    drive && drive?.start_date && DateTime.fromISO(drive?.start_date).isValid
      ? DateTime.fromISO(drive?.start_date)
      : null;
  const endDate =
    drive && drive?.end_date && DateTime.fromISO(drive?.end_date).isValid ? DateTime.fromISO(drive?.end_date) : null;
  if (startDate && currentDate <= startDate) {
    return startDate.toISO();
  }

  if (endDate && startDate && currentDate > startDate && currentDate < endDate) {
    return currentDate.toISO();
  }

  if (endDate && currentDate >= endDate) {
    return endDate.toISO();
  }

  return currentDate.toISO();
};

export const getLatestEvaluation = (evaluations) =>
  evaluations && Array.isArray(evaluations) && evaluations.length ? evaluations[0] : null;

export const getDriveSlotDuration = (drive) => {
  const slotDuration =
    drive.slot_duration && DateTime.fromISO(drive.slot_duration).isValid
      ? DateTime.fromISO(drive.slot_duration).toObject()
      : { hour: 0, minute: 60 };
  const parseDuration = slotDuration.hour * 60 + slotDuration.minute;
  return parseDuration;
};

export const getSelectedDateDriveTimesRange = (drive, selectedDate) => {
  const times = {
    start: {
      hours:
        drive.start_date && DateTime.fromISO(drive.start_date).isValid ? DateTime.fromISO(drive.start_date).hour : 9,
      minutes:
        drive.start_date && DateTime.fromISO(drive.start_date).isValid ? DateTime.fromISO(drive.start_date).minute : 0,
    },
    end: {
      hours: drive.end_date && DateTime.fromISO(drive.end_date).isValid ? DateTime.fromISO(drive.end_date).hour : 22,
      minutes: drive.end_date && DateTime.fromISO(drive.end_date).isValid ? DateTime.fromISO(drive.end_date).minute : 0,
    },
  };

  return {
    startTime: DateTime.fromISO(selectedDate)
      .startOf('day')
      .plus({ hours: times.start.hours, minutes: times.start.minutes }),
    endTime: DateTime.fromISO(selectedDate).startOf('day').plus({ hours: times.end.hours, minutes: times.end.minutes }),
  };
};

export const getTargetDateSlots = (slots, drive, selectedDate) => {
  const driveTime = getSelectedDateDriveTimesRange(drive, selectedDate);
  const filteredSlots = filter(slots, (driveGuest) => {
    const from =
      driveGuest.from && DateTime.fromISO(driveGuest.from).isValid ? DateTime.fromISO(driveGuest.from) : null;
    if (from && from <= driveTime.endTime && from >= driveTime.startTime) {
      return true;
    }
    return false;
  });
  return sortBy(
    map(filteredSlots, (slot) => ({
      ...slot,
      sortOrder: new Date(slot.from).getTime(),
    })),
    'sortOrder',
  );
};

export const getAllSlotsByDate = (slots, selectedDate) => {
  const filteredSlots = filter(slots, (driveGuest) => {
    const from =
      driveGuest.from && DateTime.fromISO(driveGuest.from).isValid ? DateTime.fromISO(driveGuest.from) : null;
    if (from && from.hasSame(DateTime.fromISO(selectedDate), 'day')) {
      return true;
    }
    return false;
  });
  return sortBy(
    map(filteredSlots, (slot) => ({
      ...slot,
      sortOrder: new Date(slot.from).getTime(),
    })),
    'sortOrder',
  );
};

export const shouldDisableDriveActionsAsPerStatus = (drive) => {
  const status = getFormattedDriveStatus(drive?.status || drive?.drive_status)?.key;
  return [driveStatus.COMPLETED, driveStatus.CANCELLED, driveStatus.INACTIVE].includes(status);
};

export const shouldDisableDriveActionsAsPerPastDate = (selectedDate) => {
  const todaysDate = process.env.NODE_ENV === 'test' ? DateTime.fromISO('2022-12-20T00:00:00+00:00') : DateTime.now();
  const parseSelectedDate = DateTime.fromISO(selectedDate);
  return parseSelectedDate.startOf('day') < todaysDate.startOf('day');
};

export const shouldDisableDriveAction = (drive, selectedDate) => {
  const disableAsPerStatus = shouldDisableDriveActionsAsPerStatus(drive);
  const disableAsPerOccurrence = shouldDisableDriveActionsAsPerOccurrence(selectedDate, drive);
  const disableAsPerDate = shouldDisableDriveActionsAsPerPastDate(selectedDate);

  if (disableAsPerDate) {
    return true;
  }

  if (disableAsPerStatus) {
    return true;
  }

  if (disableAsPerOccurrence) {
    return true;
  }

  return false;
};

export const getSelectedDriveInstance = (selectedDate, drive) =>
  find(drive?.drives, (driveInstance) => {
    const parsedSelectedDate = DateTime.fromISO(selectedDate);
    const parsedDriveInstanceStartDate =
      driveInstance?.start_date && DateTime.fromISO(driveInstance?.start_date).isValid
        ? DateTime.fromISO(driveInstance?.start_date)
        : null;
    return parsedDriveInstanceStartDate ? parsedDriveInstanceStartDate.hasSame(parsedSelectedDate, 'day') : false;
  });

export const parseMeetingParticipants = (meeting, role, authUsers) => {
  const participants = filter(
    meeting?.meeting_participants,
    (participant) => lowerCase(participant?.participant_role || '') === lowerCase(role || ''),
  );
  if (
    lowerCase(role || '') !== lowerCase(meetingParticipantRole.GUEST || '') &&
    authUsers &&
    Array.isArray(authUsers) &&
    authUsers.length
  ) {
    return map(participants, (participant) => {
      const authUser = find(authUsers, ['id', participant?.user_id]);
      const updatedMeta = { ...participant?.meta };
      if (participant?.meta?.proposed_panel_id) {
        updatedMeta.proposedPanel = find(authUsers, ['id', participant?.meta?.proposed_panel_id]);
      }
      return {
        ...participant,
        email: authUser?.email,
        name: authUser?.name,
        roles: authUser?.roles,
        username: authUser?.username,
        authUser,
        meta: updatedMeta,
      };
    });
  }
  return participants;
};

export const getMeetingInterviewers = (participants, authEvaluators) =>
  map(parseMeetingParticipants(participants), ({ id, ...participant }) => {
    const user = find(authEvaluators, ['id', participant?.user_id]);
    return {
      ...participant,
      participant_id: id,
      ...user,
    };
  });

export const formatMeetingDataWithWcs = (meetings, workflowCandidateSteps, authUsers, providers = []) =>
  map(meetings, (meeting) => {
    const guestParticipant = get(parseMeetingParticipants(meeting, meetingParticipantRole.GUEST), '0');
    const wcs = find(
      workflowCandidateSteps,
      (workflowCandidateStep) => workflowCandidateStep.id === Number(meeting?.workflow_candidate_step_id),
    );
    const interviewOrganizers = parseMeetingParticipants(meeting, meetingParticipantRole.ORGANIZER, authUsers);
    const formattedProviders = formatProviders(providers);

    return {
      ...wcs,
      ...meeting,
      cancelledBy: find(authUsers, (item) => item.id === meeting?.cancelled_by),
      workflow_candidate_step_id: wcs?.id,
      interviewerParticipants: parseMeetingParticipants(meeting, meetingParticipantRole.PARTICIPANT, authUsers),
      interviewOrganizer: interviewOrganizers.length ? interviewOrganizers[0] : wcs.auth_invited_by,
      guestParticipant,
      meeting_id: meeting?.id,
      meeting_details: meeting,
      formattedDuration: find(durationList, (item) => item.value === meeting?.duration),
      serviceProvider:
        (formattedProviders && find(formattedProviders, (item) => item?.id === meeting?.provider_service_id)) ||
        get(formattedProviders, '0'),
    };
  });

export const formatProviders = (data) =>
  data.map((item) => {
    let label;
    const name = item?.meeting_service_provider_name;

    if (name === 'IN_PERSON') {
      label = 'In-person';
    } else if (name === 'INTERVIEW_ROOM') {
      label = 'Interview Room';
    } else {
      label = capitalize(name);
    }

    return {
      ...item,
      value: name,
      label,
    };
  });

export const shouldIgnoreRowClick = (element, className = 'ignoreRowClick', terminalCheck = 'TD') => {
  if ((element?.className || '').includes(className)) {
    return true;
  }
  if (element?.tagName === terminalCheck) {
    return false;
  }
  if (element?.parentElement) {
    return shouldIgnoreRowClick(element?.parentElement, className, terminalCheck);
  }
  return false;
};

// export const getCurrentWscMeeting = (wcs, meetings) => {
//   const wcsMeetings = filter(meetings, meeting => Number(meeting.external_id) === wcs?.id);
//   let activeMeeting = {};
//   if (wcsMeetings && wcsMeetings.length) {
//     activeMeeting =
//       find(wcsMeetings, ['meeting_status', 'onGoing']) ||
//       find(wcsMeetings, ['meeting_status', 'inProgress']) ||
//       find(wcsMeetings, ['meeting_status', 'scheduled']) ||
//       find(wcsMeetings, ['meeting_status', 'draft']) ||
//       find(wcsMeetings, ['meeting_status', 'completed']) ||
//       find(wcsMeetings, ['meeting_status', 'cancelled']) ||
//       find(wcsMeetings, ['meeting_status', 'inActive']) ||
//       wcsMeetings[0];
//     return {
//       ...activeMeeting,
//       ...wcs,
//       meeting_id: activeMeeting?.id,
//       workflow_candidate_step_id: wcs?.id,
//       interviewerParticipants: parseMeetingParticipants(activeMeeting, meetingParticipantRole.PARTICIPANT),
//       guestParticipant: get(parseMeetingParticipants(activeMeeting, meetingParticipantRole.GUEST), '0'),
//       allMeetings: wcsMeetings,
//     };
//   }
//   return wcs;
// };

export const getHasuraHeaders = (graphToken) => get(parseJwt(graphToken), 'https://hasura.io/jwt/claims');

export const getHasuraHeaderByName = (graphToken, name) => {
  const parsedHeaders = getHasuraHeaders(graphToken);
  return get(parsedHeaders, name) || get(parsedHeaders, (name || '').toLowerCase());
};

export const getRequiredPathRoleAccess = () => {
  const path = window.location.href;
  const isPanelRoute = path.includes('/panel/');
  const isRecruiterRoute = path.includes('/scheduling/');
  if (isPanelRoute) {
    return ROLE_EVALUATOR;
  }
  if (isRecruiterRoute) {
    return ROLE_RECRUITER;
  }
  return ROLE_MASTER_RECRUITER;
};

export const validateRoleAccess = (roles, requiredRole = getRequiredPathRoleAccess()) => includes(roles, requiredRole);

export const getDefaultContext = (graphToken) => {
  const roles = getHasuraHeaderByName(graphToken, 'X-Hasura-Allowed-Roles');
  const requiredRole = getRequiredPathRoleAccess();
  const haveAccess = validateRoleAccess(roles, requiredRole);
  return {
    headers: {
      'x-hasura-role': haveAccess ? requiredRole : getCurrentRole(roles),
    },
  };
};

export const getParticipantCountByStatus = (data, key) => {
  const newArr = data?.map((item) => item?.[key]);
  const obj = {};
  newArr?.forEach((item) => {
    if (item) {
      if (obj[item]) {
        obj[item] += 1;
      } else {
        obj[item] = 1;
      }
    }
  });
  return obj;
};

export const differenceTime = (time) => {
  if (time) {
    const defaultDate =
      process.env.NODE_ENV === 'test' ? DateTime.fromISO('2023-01-10T08:00:00+00:00') : DateTime.now();
    const from = DateTime.fromISO(time);
    const currentTime = defaultDate;
    const diff = from.diff(currentTime);
    const seconds = diff.as('seconds');
    const formattedDuration = Duration.fromMillis(seconds * 1000);
    const result = formattedDuration.toFormat('dd:hh:mm:ss');
    return result;
  }
  return null;
};

export const timeToDisplay = (from) => {
  if (from) {
    const [days, hours, minutes] = differenceTime(from).split(':');
    if (Number(days)) {
      return { diff: Math.abs(days) > 1 ? `${Math.abs(days)} days` : `${Math.abs(days)} day`, postive: days > 0 };
    }
    if (Number(hours) && !Number(days)) {
      return { diff: Math.abs(hours) > 1 ? `${Math.abs(hours)} hours` : `${Math.abs(hours)} hour`, postive: hours > 0 };
    }
    if (Number(minutes) && !Number(days) && !Number(hours)) {
      return { diff: `${Math.abs(minutes)} mins`, postive: minutes > 0 };
    }
  }

  return null;
};

export const convertDate = (date) => {
  if (date) {
    return DateTime.fromISO(date).isValid ? DateTime.fromISO(date).toFormat('dd MMM yyyy') : null;
  }
  return null;
};

export const parseDuration = (duration) => {
  if ((duration || '').includes(':')) {
    const [hours, minutes, seconds] = duration.split(':');
    return Duration.fromObject({
      hours: Number(hours),
      minutes: Number(minutes),
      seconds: Number(seconds),
    });
  }
  return Duration.fromObject({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
};

export const getFormattedProposedNewTime = (participant, duration, isCandidate) => {
  const time = get(participant, 'meta.proposed_new_time');
  const newProposedTimeAt = get(participant, 'meeting_participant_histories[0]');
  const startTime = DateTime.fromISO(time).isValid ? DateTime.fromISO(time) : null;
  const parsedDuration = parseDuration(duration)?.isValid
    ? parseDuration(duration)
    : { hours: 0, minutes: 30, seconds: 0 };
  const endTime = startTime ? startTime.plus(parsedDuration) : null;
  const formattedStartTime = startTime ? DateTime.fromISO(startTime).toFormat('ccc dd, LLL yyyy | hh:mm a') : null;
  const formattedEndTime = endTime ? DateTime.fromISO(endTime).toFormat('hh:mm a') : null;
  const formattedSlot = formattedStartTime && formattedEndTime ? `${formattedStartTime} - ${formattedEndTime}` : '-';
  const formattedNewProposedNewTimeAt = newProposedTimeAt
    ? DateTime.fromISO(newProposedTimeAt?.created_at).toFormat('DD, hh:mm a')
    : '-';
  return {
    displayName: getDisplayName(get(participant, isCandidate ? 'guest' : 'authUser')),
    proposed_new_time: formattedSlot,
    proposed_new_time_at: formattedNewProposedNewTimeAt,
  };
};

export const copyTextContent = async (content) => {
  try {
    if (content) {
      await navigator.clipboard.writeText(content);
    }
  } catch (e) {
    catchError(e);
  }
};

export const getDurationFromCurrentTime = (data) => {
  const currentTime = DateTime.now();
  const parsedDateTime = data && DateTime.fromISO(data).isValid ? DateTime.fromISO(data) : null;
  if (parsedDateTime) {
    const {
      values: { milliseconds },
    } = parsedDateTime.diff(currentTime);

    if (milliseconds > 0) {
      return Duration.fromMillis(milliseconds);
    }
    return null;
  }
  return null;
};

export const getDriveRegistrationUrl = (driveSchedule, tenant_id) => {
  let url = `${process.env.CANDIDATE_X_URL}/scheduling/drive-registration?driveScheduleId=${driveSchedule?.id}`;
  if (tenant_id) {
    url += `&tid=${tenant_id}`;
  }
  return url;
};

export const getDayPrefix = (date) => {
  const parsedDate = +DateTime.fromISO(date).startOf('day');
  const currentDate = getCurrentDate().startOf('day');
  if (parsedDate === +currentDate) {
    return 'Today, ';
  }
  if (parsedDate === +currentDate.minus({ days: 1 })) {
    return 'Yesterday, ';
  }
  if (parsedDate === +currentDate.plus({ days: 1 })) {
    return 'Tomorrow, ';
  }
  return `${DateTime.fromISO(date).weekdayLong}, `;
};

export const parseDriveScheduleWithDrive = (driveSchedule, providers, tenant_id, interviewSteps) => ({
  ...driveSchedule,
  drive_status: driveSchedule?.status,
  registration_url: getDriveRegistrationUrl(driveSchedule, tenant_id),
  drives: map(driveSchedule?.drive_occurrences, (driveInstance) => ({
    ...driveInstance,
    drive_status: driveInstance?.status,
    drive_users: map(driveInstance?.drive_occurrence_users, (driveUser) => ({
      ...driveUser,
      drive_id: driveUser?.drive_occurrence_id,
    })),
    drive_guests: map(driveInstance?.drive_occurrence_guests, (driveGuest) => ({
      ...driveGuest,
      drive_id: driveGuest?.drive_occurrence_id,
    })),
    rooms: getFormattedDriveRooms(
      map(driveInstance.rooms, (room) => ({
        ...room,
        drive_id: room?.drive_occurrence_id,
        step_id: room?.workflow_step_id,
        drive_guests: map(room?.drive_occurrence_guests, (driveGuest) => ({
          ...driveGuest,
          drive_id: driveGuest?.drive_occurrence_id,
        })),
        drive_users: map(room?.drive_occurrence_users, (driveUser) => ({
          ...driveUser,
          drive_id: driveUser?.drive_occurrence_id,
        })),
      })),
      interviewSteps,
    ),
  })),
  formattedDuration: find(durationList, (item) => item.value === driveSchedule?.slot_duration),
  serviceProvider:
    (providers && find(providers, (item) => item?.id === driveSchedule?.provider_service_id)) || get(providers, '0'),
  recurrence: getRecurrenceDetails(driveSchedule?.cron_expression),
});

export const getBookedDriveGuests = (drive_occurrence_guests) =>
  filter(drive_occurrence_guests, (drive_occurrence_guest) =>
    [driveGuestStatus.BOOKED].includes(getFormattedDriveGuestStatus(drive_occurrence_guest?.status).key),
  );

export const getNoShowDriveGuests = (drive_occurrence_guests) =>
  filter(drive_occurrence_guests, (drive_occurrence_guest) =>
    [driveGuestStatus.NO_SHOW].includes(getFormattedDriveGuestStatus(drive_occurrence_guest?.status).key),
  );

export const getReadyDriveGuests = (drive_occurrence_guests) =>
  filter(drive_occurrence_guests, (drive_occurrence_guest) =>
    [driveGuestStatus.READY].includes(getFormattedDriveGuestStatus(drive_occurrence_guest?.status).key),
  );

export const getInProgressDriveGuests = (drive_occurrence_guests) =>
  filter(drive_occurrence_guests, (drive_occurrence_guest) =>
    [driveGuestStatus.IN].includes(getFormattedDriveGuestStatus(drive_occurrence_guest?.status).key),
  );

export const getCompletedDriveGuests = (drive_occurrence_guests) =>
  filter(drive_occurrence_guests, (drive_occurrence_guest) =>
    [driveGuestStatus.OUT].includes(getFormattedDriveGuestStatus(drive_occurrence_guest?.status).key),
  );

export const getCancelledDriveGuests = (drive_occurrence_guests) =>
  filter(drive_occurrence_guests, (drive_occurrence_guest) =>
    [driveGuestStatus.CANCELLED].includes(getFormattedDriveGuestStatus(drive_occurrence_guest?.status).key),
  );

export const handleEnterKeyPress = (event, callback) => {
  if (event.keyCode === 13) {
    callback(event);
  }
};

export const parseDateTime = (date) => (date && DateTime.fromISO(date).isValid ? DateTime.fromISO(date) : null);
export const parseJSDateTime = (date) => (date && DateTime.fromJSDate(date).isValid ? DateTime.fromJSDate(date) : null);

export const differenceTimeInMins = (start, end) => {
  if (start && end) {
    const from = DateTime.fromISO(start);
    const currentTime = DateTime.fromISO(end);
    const diff = currentTime.diff(from);
    const seconds = diff.as('seconds');
    const formattedDuration = Duration.fromMillis(seconds * 1000);
    const result = formattedDuration.toFormat('mm');
    return result;
  }
  return null;
};

export const durationEndStartTime = (payload, duration) => {
  const { start_date, end_date } = payload;
  const startDate = parseDateTime(start_date);
  const endDate = parseDateTime(end_date);
  const startTime = DateTime.now().startOf('day').plus({ hours: startDate.hour, minutes: startDate.minute });
  const endTime = DateTime.now().startOf('day').plus({ hours: endDate.hour, minutes: endDate.minute });
  if (+differenceTimeInMins(startTime, endTime) < +duration) {
    return {
      title: 'Difference less than duration',
      summary: 'Difference between start and end time cannot be less than the interview duration',
    };
  }
  return false;
};

export const getDriveOccurrenceDateById = (driveOccurrences) => {
  const qs = queryStr.parse(window.location.search);
  const driveOccurrenceId = qs?.drive_occurrence_id;
  if (driveOccurrenceId) {
    const driveOccurrence = find(driveOccurrences, ['id', Number(driveOccurrenceId)]);
    return driveOccurrence?.start_date;
  }
  return null;
};

export const mapDriveOccurrenceGuestWithMeeting = ({ driveOccurrenceGuests = [], meetings = [], authUsers = [] }) =>
  map(driveOccurrenceGuests, (driveOccurrenceGuest) => {
    let interviewer = null;
    const meeting = find(meetings, (m) => {
      if (
        m?.drive_occurrence_id === driveOccurrenceGuest?.drive_occurrence_id &&
        m?.room_id === driveOccurrenceGuest?.room_id
      ) {
        return find(
          m?.meeting_participants,
          (participant) =>
            participant?.participant_role === meetingParticipantRole.GUEST &&
            participant?.guest_id === driveOccurrenceGuest?.guest_id,
        );
      }
      return false;
    });
    if (meeting) {
      const participant = find(meeting.meeting_participants, ['participant_role', meetingParticipantRole.PARTICIPANT]);
      interviewer = participant?.user_id ? find(authUsers, ['id', participant?.user_id]) : null;
    }
    return {
      ...driveOccurrenceGuest,
      from: meeting?.from || driveOccurrenceGuest?.from,
      to: meeting?.to || driveOccurrenceGuest?.to,
      meeting,
      interviewer,
    };
  });

const getFormattedRoomDriveGuest = (rooms) =>
  map(rooms, (room) => {
    const dg = map(room.drive_guests, (guest) => ({
      ...guest,
      sortOrder: +parseDateTime(guest?.from),
    }));
    return {
      ...room,
      drive_guests: sortBy(dg, 'sortOrder'),
    };
  });

export const mapTempSlot = ({ guests: allGuest, driveOccurrence, driveSchedule }) => {
  const rooms = getFormattedRoomDriveGuest(driveOccurrence.rooms);
  const guests = sortBy(
    filter(allGuest, (guest) => !(guest.from && guest.to)),
    'id',
  );

  const duration = driveSchedule?.formattedDuration?.duration;
  const driveOccurrenceStartTime = driveOccurrence.start_date;
  const driveOccurrenceEndTime = driveOccurrence.end_date;
  const updatedGuests = [];

  const freeSlots = getAvailableRoomSlots({
    startTime: driveOccurrenceStartTime,
    endTime: driveOccurrenceEndTime,
    rooms,
    duration,
  });

  // eslint-disable-next-line no-restricted-syntax
  for (const guest of guests) {
    const slot = freeSlots.shift();
    if (slot?.room_id) {
      const record = {
        ...guest,
        from: slot.from,
        to: slot.to,
        room_id: slot.room_id,
        sortOrder: slot.sortOrder,
      };
      const roomIndex = findIndex(rooms, ['id', slot.room_id]);
      const room = rooms[roomIndex];
      rooms[roomIndex] = {
        ...room,
        drive_guests: [...room.drive_guests, record],
      };
      updatedGuests.push(record);
    }
  }
  return updatedGuests;
};

export const getCurrentDate = () =>
  process.env.NODE_ENV === 'test' || process.env.STORYBOOK_APP_ENV === 'storybook'
    ? parseDateTime('2022-12-07T12:00:00+05:30')
    : DateTime.now();

export function groupSlotsByStartTime(events) {
  const groupedSlots = [];

  // Sort events by start time
  const sortedEvents = events.sort((a, b) => new Date(a.from) - new Date(b.from));

  let currentSlot = null;

  // eslint-disable-next-line no-restricted-syntax
  for (const event of sortedEvents) {
    if (!currentSlot || new Date(event.from) >= currentSlot.to) {
      // Create a new slot
      currentSlot = {
        from: new Date(event.from),
        to: new Date(event.to),
        events: [event],
      };
      groupedSlots.push(currentSlot);
    } else {
      // Chain the end time of the current slot
      currentSlot.to = new Date(event.to);
      currentSlot.events.push(event);
    }
  }

  return groupedSlots;
}

export const getUniqueCandidates = (candidates) => {
  const arr = [];
  if (candidates) {
    candidates?.forEach((item) => {
      arr.push(item?.candidate_id);
    });
  }
  return uniq(arr);
};

export const getUniqueCandidateStepData = (candidateStep) => {
  const arr = [];
  if (candidateStep) {
    candidateStep?.forEach((item) => {
      arr.push(item?.workflowCandidate?.candidate_id);
    });
  }
  return uniq(arr);
};

export const mapWorkflowCandidateWithCandidateData = (candidateData, workflowCandidatesData, step_id) =>
  workflowCandidatesData &&
  workflowCandidatesData?.map((item) => {
    const candidateDataAsPerId = find(candidateData, [
      'id',
      step_id ? item?.workflowCandidate?.candidate_id : item?.candidate_id,
    ]);
    return {
      ...item,
      candidate: candidateDataAsPerId,
    };
  });

export const mapWorkflowCandidateStepWithCandidateData = (candidateData, workflowCandidatesData) =>
  workflowCandidatesData &&
  workflowCandidatesData?.map((item) => {
    const candidateDataAsPerId = find(candidateData, ['id', item?.workflowCandidate?.candidate_id]);
    return {
      ...item,
      candidate: candidateDataAsPerId,
    };
  });

export const filterUsersDropdown = (option, searchText) => {
  const s = (searchText || '').toString().trim().toLowerCase();
  if (s) {
    let username = get(option, 'data.username', '');
    let external_id = get(option, 'data.external_id', '');
    let name = getDisplayName(option?.data);
    username = (username || '').toString().trim().toLowerCase();
    external_id = (external_id || '').toString().trim().toLowerCase();
    name = (name || '').toString().trim().toLowerCase();
    return username.includes(s) || external_id.includes(s) || name.includes(s);
  }
  return true;
};

export const mergeLatestUpdatedMeetingParticipants = (meetingUpdates, stepLevelUpdates) => {
  const guestParticipants =
    meetingUpdates &&
    flatMap(meetingUpdates, (meeting) =>
      filter(meeting.meeting_participants, { participant_role: meetingParticipantRole?.GUEST }),
    );

  const formattedMeetingUpdates = map(guestParticipants, (participant) => {
    const { guest: { name, email, external_id } = {} } = participant;
    return {
      candidate: {
        id: external_id,
        first_name: name,
        email,
      },
    };
  });

  const arr = [...formattedMeetingUpdates, ...stepLevelUpdates];

  return uniqBy(arr, 'candidate.id');
};

export const extractWorkflowCandidateStepIds = (records) => {
  let meetings = map(records, (record) => record.subRows);
  meetings = flatMap(meetings);
  meetings = filter(meetings, (d) => d);
  const wcsIds = map(meetings, (d) => d?.workflow_candidate_step_id);
  return uniq(wcsIds);
};

export function formatRolesArray(roles) {
  function formatRole(role) {
    const words = role.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    return words.join(' ');
  }

  return roles?.map(formatRole).join(', ');
}

export function getDataForLocalForage(key) {
  return localforage.getItem(key);
}

export function storeDataToLocalForage(key, value) {
  return localforage.setItem(key, value);
}

export const authDetails = (workflow_candidate_step) => {
  const candidate = workflow_candidate_step?.workflowCandidate?.candidate;
  const authRequests = candidate?.ca_profile?.auth_requests;
  if (authRequests && authRequests.length) {
    return find(
      authRequests,
      (authRequest) =>
        authRequest?.description?.workflow_candidate_step_id === workflow_candidate_step?.workflow_candidate_step_id &&
        authRequest?.request_type === 'VERIFY_IMAGE',
    );
  }
  return null;
};

export const promiseDelay = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

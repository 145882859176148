import { authenticateToken } from 'containers/auth/LoginPage/api';
import { eventChannel } from 'redux-saga';
import Auth from 'utils/auth';
import { app, auth } from 'utils/firebase';
import { GET_CUSTOM_TOKEN_BY_ID_TOKEN } from './queries';

export const createEventChannel = () =>
  eventChannel((emitter) => {
    const unsubscribe = auth.onAuthStateChanged((user) => emitter({ user }));
    return () => unsubscribe();
  });

export async function init() {
  const search = new URLSearchParams(window.location.search);
  const customToken = search.get('accessToken') || search.get('custom_token');
  if (customToken) {
    await Auth.logout();
    await authenticateToken({ access_token: customToken });
    if (search.get('accessToken')) {
      search.delete('accessToken');
    } else {
      search.delete('custom_token');
    }
    window.location.search = search.toString();
  }
  return app;
}

export const requestCustomToken = async ({ id_token }) => {
  const apolloClient = await Auth.clientNoAuth();
  const { data } = await apolloClient.mutate({
    mutation: GET_CUSTOM_TOKEN_BY_ID_TOKEN,
    fetchPolicy: 'network-only',
    variables: { id_token },
  });
  return {
    data,
  };
};

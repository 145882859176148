import { flatten, map, sortBy } from 'lodash';
import { DateTime } from 'luxon';

function isSlotFree({ slot, existingMeetings }) {
  const slotDateTime = DateTime.fromISO(slot);

  const overlappingEvent = existingMeetings.find(
    (event) => slotDateTime >= DateTime.fromISO(event.from) && slotDateTime < DateTime.fromISO(event.to),
  );
  return !overlappingEvent;
}

export function findFreeSlots({ startTime, endTime, room, duration }) {
  const meetings = map(room.drive_guests, (driveGuest) => ({
    ...driveGuest,
    sortOrder: +DateTime.fromISO(driveGuest.from),
  }));
  const existingMeetings = sortBy(meetings, 'sortOrder');
  const start = DateTime.fromISO(startTime);
  const end = DateTime.fromISO(endTime);

  const allTimeSlots = [];
  let currentSlot = start;
  while (currentSlot < end) {
    const slotEnd = currentSlot.plus({ minutes: 15 }); // Assuming the slot duration is 15 minutes
    if (isSlotFree({ slot: currentSlot.toISO(), existingMeetings })) {
      let nextSlot = slotEnd;
      while (nextSlot < end && isSlotFree({ slot: nextSlot.toISO(), existingMeetings })) {
        nextSlot = nextSlot.plus({ minutes: 15 });
      }
      allTimeSlots.push({ start: currentSlot, end: nextSlot });
      currentSlot = nextSlot;
    } else {
      currentSlot = slotEnd;
    }
  }

  const formattedSlots = map(allTimeSlots, (slot) => {
    const allSlots = [];
    currentSlot = slot.start;
    while (currentSlot < slot.end) {
      allSlots.push({
        sortOrder: +currentSlot,
        room_id: room?.id,
        from: currentSlot.toISO(),
        to: currentSlot.plus({ minutes: duration }).toISO(),
      });
      currentSlot = currentSlot.plus({ minutes: duration });
    }
    return allSlots;
  });

  return sortBy(flatten(formattedSlots), 'sortOrder');
}

export function getAvailableRoomSlots({ startTime, endTime, rooms, duration }) {
  const allFreeSlots = map(rooms, (room) => findFreeSlots({ startTime, endTime, room, duration }));
  return sortBy(flatten(allFreeSlots), 'sortOrder');
}

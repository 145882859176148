import { capitalize, find, lowerCase, get } from 'lodash';

export const driveGuestStatus = {
  INVITED: 'INVITED',
  REGISTERED: 'REGISTERED',
  BOOKED: 'BOOKED',
  READY: 'READY',
  IN: 'IN',
  OUT: 'OUT',
  IN_ACTIVE: 'IN_ACTIVE',
  CANCELLED: 'CANCELLED',
  TENTATIVE: 'TENTATIVE',
  CANNOT_ALLOCATED: 'CANNOT_ALLOCATED',
  NO_SHOW: 'NO_SHOW',
};

export const driveGuestStatusTheme = {
  INVITED: {
    light: 'bg-slate-400',
    dark: 'bg-slate-700',
    text: 'text-slate-400',
    border: 'border border-slate-300',
    backgroundColor: 'bg-slate-50',
    title: 'text-slate-700',
    statusBg: 'bg-slate-400',
    bg: 'bg-slate-50',
    statusText: 'text-white',
    accent: 'bg-slate-400',
    hover: 'hover:border hover:border-blue-300',
  },
  REGISTERED: {
    light: 'bg-amber-700',
    dark: 'bg-amber-800',
    text: 'text-amber-700',
    border: 'border border-amber-300',
    backgroundColor: 'bg-amber-50',
    title: 'text-amber-700',
    statusBg: 'bg-amber-700',
    bg: 'bg-amber-50',
    statusText: 'text-white',
    accent: 'bg-amber-700',
    hover: 'hover:border hover:border-blue-300',
  },
  BOOKED: {
    light: 'bg-green-500',
    dark: 'bg-green-700',
    text: 'text-gray-700',
    border: 'border border-gray-300',
    backgroundColor: 'bg-gray-50',
    title: 'text-green-700',
    statusBg: 'bg-green-400',
    bg: 'bg-gray-50',
    statusText: 'text-white',
    accent: 'bg-green-400',
    hover: 'hover:border hover:border-blue-300',
  },
  IN: {
    light: 'bg-blue-600',
    dark: 'bg-blue-700',
    text: 'text-blue-700',
    border: 'border border-blue-300',
    backgroundColor: 'bg-blue-50',
    title: 'text-blue-600',
    statusBg: 'bg-blue-600',
    bg: 'bg-blue-50',
    statusText: 'text-white',
    accent: 'bg-blue-600',
    hover: 'hover:border hover:border-blue-400',
  },
  READY: {
    light: 'bg-purple-600',
    dark: 'bg-purple-700',
    text: 'text-purple-600',
    border: 'border border-purple-300',
    backgroundColor: 'bg-purple-100',
    title: 'text-purple-600',
    statusBg: 'bg-purple-600',
    bg: 'bg-purple-100',
    statusText: 'text-white',
    accent: 'bg-purple-600',
    hover: 'hover:border hover:border-blue-300',
  },
  OUT: {
    light: 'bg-lime-700',
    dark: 'bg-lime-800',
    text: 'text-lime-700',
    border: 'border border-green-200',
    backgroundColor: 'bg-green-50',
    title: 'text-lime-700',
    statusBg: 'bg-lime-800',
    bg: 'bg-green-50',
    statusText: 'text-white',
    accent: 'bg-lime-700',
    hover: 'hover:border hover:border-blue-300',
  },
  IN_ACTIVE: {
    light: 'bg-yellow-400',
    dark: 'bg-yellow-700',
    text: 'text-yellow-600',
    border: 'border border-yellow-300',
    backgroundColor: 'bg-yellow-50',
    title: 'text-yellow-700',
    statusBg: 'bg-yellow-400',
    bg: 'bg-yellow-50',
    statusText: 'text-white',
    accent: 'bg-yellow-700',
    hover: 'hover:border hover:border-blue-300',
  },
  CANCELLED: {
    light: 'bg-red-400',
    dark: 'bg-red-700',
    text: 'text-gray-900',
    border: 'border border-gray-300',
    backgroundColor: 'bg-gray-50',
    title: 'text-red-700',
    statusBg: 'bg-red-400',
    bg: 'bg-gray-50',
    statusText: 'text-white',
    accent: 'bg-red-400',
    hover: 'hover:border hover:border-blue-300',
  },
  TENTATIVE: {
    light: 'bg-slate-400',
    dark: 'bg-slate-700',
    text: 'text-slate-400',
    border: 'border border-slate-300',
    backgroundColor: 'bg-slate-50',
    title: 'text-slate-700',
    statusBg: 'bg-slate-400',
    bg: 'bg-slate-50',
    statusText: 'text-white',
    accent: 'bg-slate-400',
    hover: 'hover:border hover:border-blue-300',
  },
  CANNOT_ALLOCATED: {
    light: 'bg-red-400',
    dark: 'bg-red-700',
    text: 'text-red-400',
    border: 'border border-red-300',
    backgroundColor: 'bg-white',
    title: 'text-red-700',
    statusBg: 'bg-red-400',
    bg: 'bg-white',
    statusText: 'text-white',
    accent: 'bg-red-400',
    hover: 'hover:border hover:border-blue-300',
  },
  NO_SHOW: {
    text: 'text-orange-700',
    border: 'border border-yellow-200',
    backgroundColor: 'bg-yellow-50',
    hover: 'hover:border hover:border-blue-300',
    bg: 'bg-yellow-50',
  },
};

export const driveGuestStatusList = [
  {
    type: ['drive_guest'],
    key: driveGuestStatus.INVITED,
    label: 'Invited',
    theme: driveGuestStatusTheme.INVITED,
  },
  {
    type: ['drive_guest'],
    key: driveGuestStatus.REGISTERED,
    label: 'Registered',
    theme: driveGuestStatusTheme.REGISTERED,
  },
  {
    type: ['drive_guest'],
    key: driveGuestStatus.TENTATIVE,
    label: '',
    theme: driveGuestStatusTheme.TENTATIVE,
  },
  {
    type: ['drive_guest'],
    key: driveGuestStatus.READY,
    label: 'Ready',
    theme: driveGuestStatusTheme.READY,
  },
  {
    type: ['drive_guest'],
    key: driveGuestStatus.IN,
    label: 'Ongoing',
    theme: driveGuestStatusTheme.IN,
  },
  {
    type: ['drive_guest'],
    key: driveGuestStatus.OUT,
    label: 'Completed',
    theme: driveGuestStatusTheme.OUT,
  },
  {
    type: ['drive_guest'],
    key: driveGuestStatus.IN_ACTIVE,
    label: 'Inactive',
    theme: driveGuestStatusTheme.IN_ACTIVE,
  },
  {
    type: ['drive_guest'],
    key: driveGuestStatus.CANCELLED,
    label: 'Cancelled',
    theme: driveGuestStatusTheme.CANCELLED,
  },
  {
    type: ['drive_guest'],
    key: driveGuestStatus.TENTATIVE,
    label: 'Tentative',
    theme: driveGuestStatusTheme.TENTATIVE,
  },
  {
    type: ['drive_guest'],
    key: driveGuestStatus.BOOKED,
    label: 'Booked',
    theme: driveGuestStatusTheme.BOOKED,
  },
  {
    type: ['drive_guest'],
    key: driveGuestStatus.CANNOT_ALLOCATED,
    label: "Couldn't allocate",
    theme: driveGuestStatusTheme.CANNOT_ALLOCATED,
  },
  {
    type: ['drive_guest'],
    key: driveGuestStatus.NO_SHOW,
    label: 'No Show',
    theme: driveGuestStatusTheme.NO_SHOW,
  },
];

export const getFormattedDriveGuestStatus = (status) => {
  if (status && (typeof status === 'string' || Object.keys(status).length)) {
    if (status?.key && status?.theme && status?.theme?.bg) {
      return status;
    }
    const parsedStatus = status && typeof status === 'string' ? status : status?.status;
    const variant = find(
      driveGuestStatusList,
      (s) =>
        lowerCase(s.key) ===
        lowerCase(
          (parsedStatus || '').trim() ||
            lowerCase(s.key) === lowerCase(get(driveGuestStatus, (parsedStatus || '').toUpperCase())),
        ),
    );
    if (variant && variant?.key) {
      return variant;
    }
    return parsedStatus ? { ...driveGuestStatusList[0], key: parsedStatus, label: capitalize(parsedStatus) } : null;
  }
  return status;
};
